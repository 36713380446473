<template>
  <div class="q-mb-sm">
    <q-input dense filled v-model="valor" mask="time" :disable="bDisabled" :label="`${sLabel}${bRequired ? ' *' : ''}`" lazy-rules>
      <template v-slot:append>
        <q-icon name="access_time" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-time v-model="valor">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Close" color="primary" flat />
              </div>
            </q-time>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
  </div>
</template>

<script>

export default {
  name: "FormDate",
  emits: ['updateValor'],
  props: {
    value: {},
    sKeyField: {
      type: String,
      default: ''
    },
    sLabel: {
      type: String,
      default: ''
    },
    bRequired: {
      type: Boolean,
      default: false
    },
    bDisabled: {
      type: Boolean,
      default: false
    },
    oValorField: {}
  },
  data() {
    return {
      valor: '',
      pruebaRef: null,
      noVacio: [
        val => (val && val.length > 0) || 'Please type something',
        val => !!val || 'Field is required'
      ],
      nameRef: null,
      aItemsData: {}
    }
  },
  mounted() {
    this.aItemsData = this.oValorField;
  },
  watch: {
    valor(newValor) {
      this.$emit('updateValor', newValor)
    },

    value(newValue) {
      this.valor = newValue
    },

    oValorField(newoValorField) {
      this.valor = newoValorField[`${this.sKeyField}`]
    },

    aItemsData(newV) {
      if(newV) {
        this.valor = newV[`${this.sKeyField}`]
      }
    },
  }
}
</script>