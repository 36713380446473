<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "PostMortemInspectionPage",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Inspección Post Mortem',
        endpoint: 'postmortemInspections',
        fields: [
          {
            sName: 'date',
            sLabel: 'Fecha',
            sComponent: 'FormDate',
            nCols: '12'
          },
          {
            sName: 'id_outlet',
            sLabel: 'Expendio',
            sComponent: 'FormSelect',
            sEndPoint: 'sltAntemoremOutlets/postmortemInspections',
            sVisible: 'create',
            nCols: 6
          },
          {
            sName: 'outlet',
            sLabel: 'Expendio',
            sComponent: 'FormText',
            bDisabled: true,
            sVisible: 'edit',
            nCols: 6,
          },
          {
            sName: 'id_daily_payroll',
            sLabel: '# Animal',
            sComponent: 'FormSelect',
            sEndPoint: 'sltAntemoremAnimals/postmortemInspections',
            sDependsOn: 'id_outlet',
            sVisible: 'create',
            nCols: 6
          },
          {
            sName: 'code',
            sLabel: '# Animal',
            sComponent: 'FormText',
            bDisabled: true,
            sVisible: 'edit',
            nCols: 6,
          },
          
          {
            sName: 'id_head_cause',
            sLabel: 'Cabeza - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'head_quantity',
            sLabel: 'Cabeza - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_small_ints_cause',
            sLabel: 'Int. Delgado - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'small_ints_quantity',
            sLabel: 'Int. Delagdo - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_large_ints_cause',
            sLabel: 'Int. Grueso - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'large_ints_quantity',
            sLabel: 'Int. Grueso - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_oment_cause',
            sLabel: 'Omento - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'oment_quantity',
            sLabel: 'Omento - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_renet_cause',
            sLabel: 'Cuajo - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'renet_quantity',
            sLabel: 'Cuajo - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_callus_cause',
            sLabel: 'Callo - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'callus_quantity',
            sLabel: 'Callo - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_liver_cause',
            sLabel: 'Hígado - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'liver_quantity',
            sLabel: 'Hígado - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_lungs_cause',
            sLabel: 'Pulmones - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'lungs_quantity',
            sLabel: 'Pulmones - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: '1', name: '1' }, { id: '2', name: '2' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_legs_cause',
            sLabel: 'Patas - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'legs_quantity',
            sLabel: 'Patas - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: '1', name: '1' }, { id: '2', name: '2' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_hands_cause',
            sLabel: 'Manos - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'hands_quantity',
            sLabel: 'Manos - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: '1', name: '1' }, { id: '2', name: '2' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_udder_cause',
            sLabel: 'Ubre - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'udder_quantity',
            sLabel: 'Ubre - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_kidney_cause',
            sLabel: 'Riñones - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'kidney_quantity',
            sLabel: 'Riñones - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: '1', name: '1' }, { id: '2', name: '2' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_heart_cause',
            sLabel: 'Corazón - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'heart_quantity',
            sLabel: 'Corazón - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_booklet_cause',
            sLabel: 'Librillo - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'booklet_quantity',
            sLabel: 'Librillo - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_white_viscera_cause',
            sLabel: 'Viscera Blanca - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'white_viscera_quantity',
            sLabel: 'Viscera Blanca - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_red_viscera_cause',
            sLabel: 'Viscera Roja - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'red_viscera_quantity',
            sLabel: 'Viscera Roja - Cantidad',
            sComponent: 'FormSelect',
            aOptions: [{ id: 'TOTAL', name: 'TOTAL' }],
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_destocking_cause',
            sLabel: 'Despurgos - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'destocking_quantity',
            sLabel: 'Despurgos - Cantidad',
            sComponent: 'FormNumber',
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'id_canal_cause',
            sLabel: 'Canal - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'canal_quantity',
            sLabel: 'Canal - Cantidad',
            sComponent: 'FormNumber',
            bClearable: true,
            nCols: 6
          },

          {
            sName: 'other_organ',
            sLabel: 'Otros - Órgano',
            sComponent: 'FormText',
            nCols: 12
          },
          {
            sName: 'id_other_cause',
            sLabel: 'Otros - Causa',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCauses',
            nCols: 6
          },
          {
            sName: 'other_quantity',
            sLabel: 'Otros - Cantidad',
            sComponent: 'FormText',
            nCols: 6
          },
          {
            sName: 'insp_ganglions',
            sLabel: 'INSP. Ganglios',
            sComponent: 'FormText',
            nCols: 12
          },
        ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>