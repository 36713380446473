<template>
  <div class="container q-mx-md q-my-lg">
    <DefaultGrid
      :title="title"
      :endpoint="endpoint"
      :isFormdata="true"
      :permissions="permissions"
      :fields="fields"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
export default {
  name: "OutletsPage",
  components: {
    DefaultGrid
  },
  data() {
    return {
      title: 'Expendios',
      endpoint: 'outlets',
      fields: [
        {
            sName: 'code',
            sLabel: 'Código',
            sComponent: 'FormText',
            nCols: 4
        },
        {
            sName: 'customer',
            sLabel: 'Cliente',
            sComponent: 'FormText',
            nCols: 8
        },
        {
            sName: 'primary_phone',
            sLabel: 'Teléfono Principal',
            sComponent: 'FormText',
            nCols: 6
        },
        {
            sName: 'secondary_phone',
            sLabel: 'Teléfono Secundario',
            sComponent: 'FormText',
            nCols: 6
        },
        {
            sName: 'establishment_name',
            sLabel: 'Nombre Establecimiento',
            sComponent: 'FormText',
            nCols: 12
        },
        {
            sName: 'id_department',
            sLabel: 'Departamento',
            sComponent: 'FormSelect',
            sEndPoint: 'sltDepartments',
            nCols: 6
        },
        {
            sName: 'id_city',
            sLabel: 'Ciudad',
            sComponent: 'FormSelect',
            sEndPoint: 'sltCities',
            sDependsOn: 'id_department',
            nCols: 6
        },
        {
            sName: 'establishment_address',
            sLabel: 'Dirección Establecimiento',
            sComponent: 'FormText',
            nCols: 12
        },
      ]
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>