<template>
    <div class="flex justify-center" v-if="bLoading">
        <q-spinner color="grey" size="3em"/>
    </div>
    <div v-else style="overflow-x: scroll;">
        <table class="full-width form-table" v-show="nAnimals">
            <thead>
                <tr>
                    <th v-show="this.bEnablePending && this.bModeNew">Pendiente</th>
                    <th v-for="oComponent, key in aEditedComponents" :key="key" v-text="oComponent.sLabel" :style="{width: oComponent.sWidth}" />
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in nAnimals" :key="row">
                    <td v-if="this.bEnablePending && this.bModeNew"><q-checkbox v-model="aEnablePending" :val="row" /></td>
                    <td v-for="(oComponent, i) in aEditedComponents" :key="i">
                        <component 
                            :bHideLabel="true"
                            :is="oComponent.sComponent" 
                            :sEndPoint="oComponent.sEndPoint" 
                            :sKeyField="oComponent.sName"
                            :aOptions="oComponent.aOptions"
                            :bDisabled="oComponent.bDisabled || aMapPending[row]"
                            :oValorField="aValorFields[row-1]"
                            @updateValor="onGetValuesForm(oComponent.sName, $event, row-1)"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import FormText from '@/components/Fields/FormText.vue'
import FormArea from '@/components/Fields/FormArea.vue'
import FormDate from '@/components/Fields/FormDate.vue';
import FormSelect from '@/components/Fields/FormSelect';
import FormNumber from '@/components/Fields/FormNumber.vue';

import axiosServices from '@/services/axiosServices';


export default {
    name: 'DailyPayrollTable',
    props: {
        aSubComponents: {},
        oValorField: {},
        sKeyField: {
            type: String,
            default: ''
        },
        sEndPoint: {
            type: String,
            default: ''
        },
        bEnablePending: {
            type: Boolean,
            default: false,
        },
        bModeNew: {
            type: Boolean,
            default: false
        }
    },
    components: {
        FormText,
        FormArea,
        FormSelect,
        FormNumber,
        FormDate
    },
    data() {
        return {
            bLoading: false,
            nAnimals: 0,
            nInitialCode: null,
            nFinishCode: null,
            aValorFields: [],
            aLocalFields: [],
            aMapPending: {},
            aEnablePending: [],
            aEditedComponents: [],
        }
    },
    mounted() {
        this.onSetNewValorFields(this.oValorField);
        this.setNewEndPoint(this.sEndPoint);
    },
    methods: {
        async onGetData() {
            this.bLoading = true;
            let aEndPoints = [];
            aEndPoints = this.aSubComponents.filter(oComponent => oComponent.sEndPoint);
            aEndPoints = aEndPoints.map(oComponent => {return oComponent.sEndPoint});
            let uniquedata = new Set(aEndPoints);
            let endpoints = Array.from(uniquedata);
            let axiosData = await this.onSetEndPointsData(endpoints);
            let oEndPoints = {};
            aEndPoints.forEach((sEndPoint, key) => {
                oEndPoints[sEndPoint] = axiosData[key];
            });

            this.aEditedComponents = this.aSubComponents.map(oComponent => {
                oComponent['aOptions'] = oEndPoints[oComponent.sEndPoint] ?? [];
                return oComponent;
            });

            if(!this.aValorFields.length) {
                for (let index = parseInt(this.nInitialCode); index <= parseInt(this.nFinishCode); index++) {
                    this.aValorFields = [...this.aValorFields, { code: index }]
                }
            }

            this.bLoading = false;
        },
        onSetNewValorFields(newValorField) {
            if(Object.values(newValorField).length) {
                this.aValorFields = newValorField[this.sKeyField];
            } else {
                this.aValorFields = []
            }
        },
        onSetEndPointsData(data) {
            return new Promise((resolve, reject) => {
                Promise.all(data.map((element) => {
                    return axiosServices.onAxiosGet(element)
                        .then(response => response)
                        .catch(error => Promise.reject(error));
                }))
                .then(axiosData => resolve(axiosData))
                .catch(error => reject(error));
            });
        },
        onGetValuesForm(index, data, row) {
            if(this.aLocalFields[row]) {
                this.aLocalFields[row][index] = data;
            } else {
                this.aLocalFields[row] = {};
                this.aLocalFields[row][index] = data;
            }
            this.onSetValorField();
        },
        onSetValorField() {
            let toDelete = [];
            this.aEnablePending.forEach(nKey => {
                toDelete = [...toDelete, this.aLocalFields[nKey-1]];
            });

            let updateValor = [...this.aLocalFields];
            toDelete.forEach(oDelete => {
                updateValor = updateValor.filter(oValor => oValor.code != oDelete.code);
            });
 
            if(!this.bModeNew && (this.nAnimals > this.aValorFields.length)) 
                this.nAnimals = this.aValorFields.length;
            
            this.$emit('updateValor', updateValor)
        },
        async setNewEndPoint(sNewEndPoint) {
            this.nAnimals = 0;
            if(sNewEndPoint.split('/')[1] && sNewEndPoint.split('/')[1] !== 'undefined') {
                const response = await axiosServices.onAxiosGet(sNewEndPoint);
                let consecutive = response.data.consecutive.split('-');
                this.nAnimals = response.data.no_animals ?? 0;
                this.nInitialCode = consecutive[0];
                this.nFinishCode = consecutive[1];
                this.onGetData();
            }
        },
    },
    watch: {
        oValorField(newValorField) {
            this.onSetNewValorFields(newValorField);
        },
        sEndPoint(sNewEndPoint) {
            this.setNewEndPoint(sNewEndPoint);
        },
        aEnablePending(aNewEnablePending) {
            this.aMapPending = {};
            aNewEnablePending.forEach(nItem => {
                this.aMapPending[nItem] = true;
            });
            this.onSetValorField();
        }
    },
}
</script>
<style>
.form-table td{
    vertical-align: top;
}
</style>