<template>
  <q-layout view="hHh Lpr lff" style="min-height: 100vh;" container class="shadow-2">
    <q-header v-if="onGetLogin && onGetToken !== ''" elevated :class="$q.dark.isActive ? 'bg-secondary' : 'bg-black'">
      <q-toolbar>
        <q-btn flat @click="drawer = !drawer" round dense icon="menu" />
        <!-- <q-toolbar-title>Header</q-toolbar-title> -->
      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="onGetLogin && onGetToken !== ''"
      v-model="drawer"
      show-if-above
      :width="220"
      :breakpoint="500"
      bordered
      :class="$q.dark.isActive ? 'bg-grey-9' : 'bg-grey-3'"
    >
      <q-scroll-area class="fit">
        <q-list>

          <template v-for="(menuItem, index) in menuList" :key="index">
            <q-list v-if="menuItem.routes.length" class="rounded-borders">
              <q-expansion-item>
                <template v-slot:header>
                  <q-item-section avatar>
                    <q-icon :name="menuItem.icon" />
                  </q-item-section>

                  <q-item-section v-text="menuItem.label"></q-item-section>
                </template>

                <q-item v-for="oSubItems, key in menuItem.routes" :key="key" :id="key" clickable
                  @click="replaceRoute(oSubItems.route)">
                  <q-item v-text="oSubItems.label"></q-item>
                </q-item>
              </q-expansion-item>
            </q-list>
            <q-item v-else clickable @click="replaceRoute(menuItem.route)" :active="false" v-ripple>
              <q-item-section avatar>
                <q-icon :name="menuItem.icon" />
              </q-item-section>
              <q-item-section v-html="menuItem.label"/>
            </q-item>
            <q-separator :key="'sep' + index"  v-if="menuItem.separator" />
          </template>
          <q-item clickable @click="logout" v-ripple>
            <q-item-section avatar>
              <q-icon name="logout" />
            </q-item-section>
            <q-item-section>Logout</q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-page-container>
      <RouterView></RouterView>
    </q-page-container>
  </q-layout>
</template>
<style lang="scss">
@import './styles/App.scss';
</style>
<script>
import axiosServices from './services/axiosServices'
// import { mapGetters } from 'vuex'
import { ref } from 'vue'
import { mapGetters } from 'vuex'
export default {
  name: 'LayoutDefault',
  components: {},
  setup () {
    return {
      drawer: ref(false),
    }
  },
  data () {
    return {
      menuList: []
    }
  },
  mounted() {
    if(this.onGetLogin && this.onGetToken !== '') {
      this.onGetPermissions();
    }
  },
  methods: {  
    replaceRoute(sRoute) {
      this.$router.replace({name: sRoute})
    },
    logout() {
			this.$store.dispatch('onSetLogin', false)
			this.$store.dispatch('onSetToken', "")
      this.$router.replace({name: 'login'});
    },
    async onGetPermissions() {
      const response = await axiosServices.onAxiosGet('getpermissions');
      if(response.code == 200) {
        this.$store.dispatch('onSetMenu', response.data.routes);
        this.$store.dispatch('onSetPermissions', response.data.permissions);
      }
    }
  },
  computed: {
    ...mapGetters(['onGetLogin', 'onGetToken', 'onGetMenu', 'onGetPermission'])
  },
  watch: {
    onGetMenu(menu) {
      this.menuList = menu;
    },
    onGetLogin(status) {
      if(status) this.onGetPermissions();
    }
  }
}
</script>
<style lang="scss">
.sigfooter {
  text-align: center;
  background-color: #08a9d6;
}

.loader-sync-api-migration {
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate{
  100% {
    transform: rotate(360deg);
  }
}
</style>