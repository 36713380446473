<template>
  <div class="container q-mx-md q-my-lg">
      <LocalForm
        :title="title"
        :fields="fields"
        :endpoint="endpoint"
      />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LocalForm from '@/components/Form/LocalForm.vue';
export default {
  name: "DashboardPage",
  components: {
    LocalForm
  },
  data() {
    return {
      title: 'Firmas',
      endpoint: 'generalParams',
      fields: [
        {
          sComponent: 'FormSelect',
          sNombreCampo: 'id_administrative_assistant',
          sLabel: 'Auxiliar Administrativo',
          nCols: 6,
          sEndPoint: 'sltUsersByCharge/8',
        },
        {
          sComponent: 'FormSelect',
          sNombreCampo: 'id_quality_assistant',
          sLabel: 'Auxiliar de calidad',
          nCols: 6,
          sEndPoint: 'sltUsersByCharge/6',
        },
        {
          sComponent: 'FormSelect',
          sNombreCampo: 'id_operational_manager',
          sLabel: 'Jefe operativo',
          nCols: 6,
          sEndPoint: 'sltUsersByCharge/4',
        },
        {
          sComponent: 'FormSelect',
          sNombreCampo: 'id_quality_manager',
          sLabel: 'Jefe de calidad',
          nCols: 6,
          sEndPoint: 'sltUsersByCharge/9',
        },
        {
          sComponent: 'FormSelect',
          sNombreCampo: 'id_assistant_veterinarian',
          sLabel: 'Médico veterinario auxiliar',
          nCols: 6,
          sEndPoint: 'sltUsersByCharge/5',
        },
      ]
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>