const translations = {
    id: "#",
    name: 'Nombre',
    last_name: 'Apellido',
    full_name: 'Nombre Completo',
    country_code: 'País',
    card_id: 'Cédula',
    primary_phone: 'Teléfono',
    adress: 'Dirección',
    email: 'Correo',
    city: 'Ciudad',
    department: 'Departamento',
    created_at: 'Fecha',
    sponsor_user: 'Patrocinador Directo',
    adoptive_sponsor: 'Patrocinador Adoptivo',

    fullname: "Nombres",
    charge: "Cargo",
    document: "Documento",
    phone: "Teléfono",
    position: "Cargo",
    login: "Login",
    person: "Persona",
    expedition_city: "Ciudad Expedición",
    // PURPOSES
    // AGES
    age: "Edad",
    yard_sacrificed_time: "Hora Ingreso Corrales Sacrificio",
    code: "Código",
    description: "Descripción",
    // INCOMES
    guide_number: "No Guía",
    no_animals: '# Animales',
    total_males: "Total Machos",
    total_females: "Total Hembras",
    date_entry: "Fecha Ingreso",
    time_entry: "Hora de Ingreso",
    benefit_date: "Fecha Beneficio",
    benefit_time: "Hora de Beneficio",
    gender: "Sexo",
    purpose: "Propósito",
    outlet: "Expendio",
    colors: "Colores",
    genders: "Géneros",
    special_order: "Orden Especial",
    source: "Procedencia",
    destination: "Destino",
    sacrifice_date: "Fecha Sacrificio",
    customer: "Cliente",
    secondary_phone: "N° Secundario",
    establishment_name: "Establecimiento",
    establishment_address: "E.Dirección",
    signature_date: "Fecha Firma",
    signature: "Firma",
    owner: "Propietario",
    buyer: "Comprador",
    route: "Ruta",
    quantity: "Cantidad",
    orders: "Ordenes",
    date: "Fecha",
    type: "Tipo",
    intestines_quantity: 'Intestinos. Cant',
    intestines_cause: 'Instestinos. Causa',
    liver_quantity: 'Higado. Cant',
    livers_cause: 'Hígado. Causa',
    lungs_quantity: 'Pulmón. Cant',
    lungs_cause: 'Pulón. Causa',
    udders_quantity: 'Ubres. Cant',
    udders_cause: 'Ubres. Causa',
    legs_quantity: 'Patas. Cant',
    legs_cause: 'Patas. Causa',
    hands_quantity: 'Manos. Cant',
    hands_cause: 'Manos. Causa',
    purges_quantity: 'Despurgos. Cant',
    purges_cause: 'Despurguos. Causa',
    other_organ: 'Otros. Organo',
    other_cause: 'Otros. Causa',
    other_quantity: 'Otros. Cantidad',
    insp_ganglions: 'Insp. Ganglios',
    verified_by: 'Verificado',
    supervised_by: 'Supervisado',
    elaborated_by: 'Elaborado',
    species: 'Especie',
    
    small_ints: 'Ints. Delgado',
    large_ints: 'Ints. Grueso',
    liver: 'Hígado',
    lung: 'Pulmón',
    udders: 'Ubres',
    head: 'Cabeza',
    hands: 'Manos',
    legs: 'Patas',
    others: 'Otros',
    destocking: 'Despurgos (kg)',
    booklet: 'Librillo',
    observations: 'Observaciones',
    callus: 'Callo',
    rennet: 'Cuajo',

    entries: 'Registros',

    plate: 'Placa',
    driver_name: 'Nombre conductor',
    driver_document: 'Documento conductor',
    refrigerated: 'Refrigerado',
    isothermal: 'Isotermo',
    temperature: 'Temperatura',

    corral_number: '# Corral',
    guide: 'Guía',
    animal_code: '# Animal',
    corral_entry: 'Hora de ingreso a corral de sacrificio',
    rest_time: 'Tiempo de descanso',
    veterinary: 'Veterinario',

    
    delivery_time: 'Hora Parto',
    iron: 'Hierro',
    corral_location: 'Ubicación corral',
    weight: 'Peso',
    heart_frequency: 'Frecuencia Cardiaca',
    respiratory_frequency: 'Frecuencia Respiratoria',
    findings: 'Hallazgos',
    final_definition_feto: 'Disposición Final Feto',
    cause_forfeiture: 'Causa de decomiso',
    findings_and_observations: 'Hallazgos y Observaciones',
    file_attached: 'Archivo adjunto',
    consecutive: 'Consecutivo',
    specie: 'Especie',
    state: 'Estado',
    corrective_actions: 'Acciones Correctivas',
    rumen_content: 'Contenido Rumial',
    milk: 'Leche',
    fecal_matter: 'Materia Fecal',
    hide: 'Piel',
    skin: 'Piel',
    hair: 'Cabello',
    abscess: 'Abseso',
    parasite: 'Parásito',
    correction: 'Corrección',
    vehicle: 'Vehículo',
    invima_code: 'Código Invima',
    dispatch_time: 'Hora despacho',
    closing_date: 'Fecha cierre',
    closing_time: 'Hora cierre',
    average_temperature: 'Temp. promedio',
    white_viscera: 'Viscera blanca',
    red_viscera: 'Viscera roja',
    heads: 'Cabezas',
    head_quantity: 'Cabeza cantidad',
    small_ints_quantity: 'Int. Delgado Cantidad',
    large_ints_quantity: 'Int. Grueso Cantidad',
    oment: 'Omento',
    oment_quantity: 'Omento Catidad',
    callus_quantity: 'Callo Cantidad',
    udder: 'Ubre',
    udder_quantity: 'Ubre Cantidad',
    kidney: 'Riñón',
    kidney_quantity: 'Riñon cantidad',
    heart: 'Corazón',
    heart_quantity: 'Corazón cantidad',
    booklet_quantity: 'Librillo cantidad',
    white_viscera_quantity: 'Viscera blanca cantidad',
    red_viscera_quantity: 'Viscera roja cantidad',
    lungs: 'Pulmones',
    destocking_quantity: 'Despurgos cantidad',
    canal_quantity: 'Canal cantidad',
    renet: 'Cuajo',
    renet_quantity: 'Cuajo Cantidad',
    final_dictament: 'Dictamen Final',
    cause_for_seizure: 'Causa de decomiso'
}

export default {
    getTranslation(string) { return (translations[string] ?? string).toUpperCase();}
}