<template>
  <div class="container q-mx-md q-my-lg">
    <DefaultGrid
      :title="title"
      :endpoint="endpoint"
      :isFormdata="true"
      :permissions="permissions"
      :fields="fields"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DefaultGrid from '@/components/Grid/GuideGrid.vue';
export default {
  name: "GuidesPage",
  components: {
    DefaultGrid
  },
  data() {
    return {
      title: 'Guías',
      endpoint: 'guides',
      fields: [
      {
          sName: 'code',
          sLabel: 'No Guía',
          sComponent: 'FormText',
          sEndPoint: '',
          nCols: 8
      },
      {
          sName: 'no_animals',
          sLabel: '# Animales',
          sComponent: 'FormNumber',
          sVisible: 'create',
          nCols: 4
      },
      {
          sName: 'no_animals',
          sLabel: '# Animales',
          sComponent: 'FormNumber',
          sVisible: 'edit',
          bDisabled: true,
          nCols: 4
      },
      {
          sName: 'consecutive',
          sLabel: 'Consecutivo',
          sComponent: 'FormText',
          sVisible: 'edit',
          bDisabled: true,
          nCols: 12
      },
      {
          sName: 'id_specie',
          sLabel: 'Especie',
          sComponent: 'FormSelect',
          sEndPoint: 'sltSpecies',
          nCols: 12
      },
      {
          sName: 'date_entry',
          sLabel: 'Fecha de Ingreso',
          sComponent: 'FormDate',
          nCols: 6
      },
      {
          sName: 'time_entry',
          sLabel: 'Hora de Ingreso',
          sComponent: 'FormTime',
          nCols: 6
      },
      {
          sName: 'id_owner',
          sLabel: 'Propietario',
          sComponent: 'FormSelect',
          sEndPoint: 'sltPersons',
          nCols: 6
      },
      {
          sName: 'id_buyer',
          sLabel: 'Comprador',
          sComponent: 'FormSelect',
          sEndPoint: 'sltPersons',
          nCols: 6
      },
      {
          sName: 'establishment_name',
          sLabel: 'Nombre del Predio',
          sComponent: 'FormText',
          sEndPoint: '',
          nCols: 12
      },
      {
          sName: 'id_department_source',
          sLabel: 'Departamento Procedencia',
          sComponent: 'FormSelect',
          sEndPoint: 'sltDepartments',
          nCols: 6
      },
      {
          sName: 'id_source',
          sLabel: 'Ciudad Procedencia',
          sComponent: 'FormSelect',
          sEndPoint: 'sltCities',
          sDependsOn: 'id_department_source',
          nCols: 6
      },

      {
          sName: 'id_department_destination',
          sLabel: 'Departamento Destino',
          sComponent: 'FormSelect',
          sEndPoint: 'sltDepartments',
          nCols: 6
      },
      {
          sName: 'id_destination',
          sLabel: 'Ciudad Destino',
          sComponent: 'FormSelect',
          sEndPoint: 'sltCities',
          sDependsOn: 'id_department_destination',
          nCols: 6
      },
      {
          sName: 'file_attached',
          sLabel: 'Archivo',
          sComponent: 'FormFile',
          nCols: 12
      },
      {
          sName: 'state',
          sLabel: 'Estado',
          sComponent: 'FormState',
          sVisible: 'edit',
          nCols: 12
      },
      ]
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>