<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :description="description"
        :endpoint="endpoint"
        :isDisableRegister="true"
        :isDisableDelete="true"
        :hideID="true"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "DailyPayrollPage",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Sacrificios Pendientes',
        description: 'En esta vista podrás revisar todos los registros de planilla diaria que quedaron pendientes por asignar la fecha de sacrificio y el número de expendio.',
        endpoint: 'antemortemDailyRecord',
        fields: [
                {
                    sName: 'id_guide',
                    sLabel: 'N° Guía',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltGuides',
                    bDisabled: true,
                    nCols: 6
                },
                {
                    sName: 'code',
                    sLabel: 'Código Asignado',
                    sComponent: 'FormText',
                    bDisabled: true,
                    nCols: 6
                },
                {
                    sName: 'id_gender',
                    sLabel: 'Sexo',
                    sComponent: 'FormSelect',
                    sEndPoint: 'genders',
                    bDisabled: true,
                    nCols: 6
                },
                {
                    sName: 'id_age',
                    sLabel: 'Edad',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltAges',
                    bDisabled: true,
                    nCols: 6
                },
                {
                    sName: 'id_color',
                    sLabel: 'Color',
                    sComponent: 'FormSelect',
                    sEndPoint: 'colors',
                    bDisabled: true,
                    nCols: 6
                },
                {
                    sName: 'id_purpose',
                    sLabel: 'Propósito',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltPurposes',
                    bDisabled: true,
                    nCols: 6
                },
                {
                    sName: 'id_product_type',
                    sLabel: 'Tipo de producto',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltProductTypes',
                    nCols: 6
                },
                {
                    sName: 'id_outlet',
                    sLabel: 'N° Expendio',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltOutlets',
                    nCols: 6
                },
                {
                    sName: 'sacrifice_date',
                    sLabel: 'Fecha de sacrificio',
                    sComponent: 'FormDate',
                    nCols: 12
                },
                {
                    sName: 'special_order',
                    sLabel: 'Orden Especial',
                    sComponent: 'FormArea',
                    nCols: 12
                },
            ],
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>