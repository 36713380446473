<template>
    <q-table
        :title="title.toUpperCase()"
        :columns="columns"
        :rows="originalRows"
        :rows-per-page-options="[10, 20, 30]"
        :loading="isLoading"
        :filter="filter"
        flat
        bordered
    >
        <template v-slot:top>
            <div class="full-width row justify-between">
                <div class="text-h5" v-text="title" />
                <div class="row" style="gap: 1.5rem;">
                    <q-input outlined dense debounce="300" v-model="filter" placeholder="Buscar...">
                        <template v-slot:append>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                    <q-btn v-if="!isDisableRegister" @click="onShowForm('new')" color="primary" label="Nuevo registro" />
                </div>
            </div>
            <div class="q-my-md" v-if="description" v-text="description" />
        </template>
        <template v-slot:body-cell-edit="props">
            <q-td class="btnsContainers" :props="props" dense>
                <div class="btnsContainers-button">
                <q-btn v-if="!isDisableDelete" @click="onDelete(props.row.id)" icon="delete" class="btnsContainers-button__general" size="sm" rounded dense flat />
                <q-btn @click="onShowForm('edit', props.row.id)" icon="edit" class="btnsContainers-button__general" size="sm" rounded dense flat />
                <q-btn v-if="hasEXCEL" @click="onDownloadPDF(props.row.id)" icon="download" class="btnsContainers-button__general" size="sm" rounded dense flat />
                </div>
            </q-td>
        </template>
    </q-table>
    <GenericForm
        :key="nKey"
        v-model="showForm"
        :isFormdata="isFormdata"
        :title="title"
        :modeNew="modeNew"
        :endpoint="endpoint"
        :fields="fields"
        :editId="editId"
        :isMaximized="isMaximized"
        @onSave="onRefresh"
        @onClose="onClose"
    />
</template>
<script>
import translate from "@/services/translate";
import axiosServices from '@/services/axiosServices';
import GenericForm from "@/components/Form/GenericForm.vue";
import { mapGetters } from "vuex";
export default {
    name: 'DefaultGrid',
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        endpoint: {
            type: String,
            default: ''
        },
        isFormdata: {
            type: Boolean,
            default: false,
        },
        hasEXCEL: {
            type: Boolean,
            default: false,
        },
        isDisableRegister: {
            type: Boolean,
            default: false,
        },
        isDisableDelete: {
            type: Boolean,
            default: false,
        },
        isMaximized: {
            type: Boolean,
            default: false,
        },
        hideID: {
            type: Boolean,
            default: false,
        },
        modalSize: {
            type: String,
            default: 'md'
        },
        fields: {},
        searchParams: {},
        permissions: {}
    },
    components: {
        GenericForm
    },
    data() {
        return {
            nKey: 0,
            filter: '',
            filtering: null,
            isLoading: false,
            isSearchAble: false,
            showForm: false,
            columns: [],
            originalRows: [],
            editId: null,
            modeNew: true,
        }
    },
    methods: {
        onShowForm(action, id) {
            this.nKey += 1;
            if(action == 'edit') {
                this.editId = id;
                this.modeNew = false;
            }  else {
                this.modeNew = true;
            }
            this.showForm = true;
        },
        onRefresh() {
            this.showForm = false;
            this.onGetData();
        },
        onClose() {
            this.showForm = false;
        },
        async onDelete(id) {
            this.$swal({
                title: '¿Deseas borrar este registro?',
                text: 'Una vez eliminado el registro no podrás recuperar esta información',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#87bc24',
                cancelButtonColor: '#F9423A',
                confirmButtonText: '¡Si, Eliminálo!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                axiosServices.onAxiosDelete(`${this.endpoint}/${id}`).then(response => {
                    if (response.code === 200) {
                    this.$swal({
                        icon: 'success',
                        title: '¡Eliminado!',
                        text: 'El registro ha sido eliminado',
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.onGetData()
                    } else {
                    this.$swal({
                        icon: 'error',
                        title: '¡Ha surgido un error!',
                        text: 'Es probable que esta table se encuentre relacionada con otras, revisa la información e intente nuevamente.',
                        confirmButtonColor: '#08a9d6'
                    })
                    }
                })
                }
            })
        },  
        async onGetData() {
            this.originalRows = [];
            this.isLoading = true;
            const response = await axiosServices.onAxiosGet(this.endpoint);
            if(!response.data.length) return this.isLoading = false;
            let aColumns = [];
            let aHeaders = Object.keys(response.data[0]);
            aColumns.push({name:"edit", label: "", align: "center", headerStyle: (aHeaders.length <= 5) ? 'width: 30px': ''})
            aHeaders.forEach(sValue => {
                if (sValue === 'id' && this.hideID) return;
                if (sValue.indexOf("id_") !== 0) {
                    let oColumns = {
                        name: sValue,
                        required: true,
                        label: translate.getTranslation(sValue).toLocaleUpperCase(),
                        align: (sValue == 'id') ? 'center' : 'left',
                        field: row => row[`${sValue}`] ?? '',
                        format: val => `${val}`,
                        sortable: true,
                        classes: (sValue == 'description') ? 'table__td--description' : "",
                        style: "word-wrap: break-word",
                        headerStyle: (sValue == 'id' && aHeaders.length <= 5) ? 'width: 20px' : '',
                        headerClasses: `table__th--${sValue}`
                    }
                    aColumns.push(oColumns);
                }
            })

            this.columns = aColumns;
            this.originalRows = response.data;

            this.isLoading = false;
        },
        onSetSearchParams() {
            if(this.searchParams?.length) {
                this.isSearchAble = true;
                this.param = this.searchParams[0]
            } else {
                this.isSearchAble = false;
            }
        },
        async onDownloadPDF(id) {
            const aResponse = await axiosServices.onAxiosPostToFile(`${this.endpoint}Format/${id}`)
            if(aResponse.status === 200) {
                const url = window.URL.createObjectURL(new Blob([aResponse.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.title.replace(/\s/g, '')}.xlsx`);
                document.body.appendChild(link);
                link.click();
            } else {
                return this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: 'No se pudo realiar la descarga'
                })
            }
        },
    },
    mounted() {
        this.onGetData();
        this.onSetSearchParams();
    },
    watch: {
        search() {
            if(!this.filtering) {
                this.filtering = setTimeout(() => {
                    this.onGetData()
                    clearTimeout(this.filtering);
                    this.filtering = null;
                }, 500);
            }
        }
    },
    computed: {
        ...mapGetters(['onGetPermission'])
    }
}
</script>