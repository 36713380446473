<template>
  <div class="q-mb-sm">
    <q-input dense filled  v-model="valor" type="date" :disable="bDisabled" :label="`${sLabel}${bRequired ? ' *' : ''}`" lazy-rules />
  </div>
</template>

<script>

export default {
  name: "FormDate",
  emits: ['updateValor'],
  props: {
    value: {},
    sKeyField: {
      type: String,
      default: ''
    },
    sLabel: {
      type: String,
      default: ''
    },
    bRequired: {
      type: Boolean,
      default: false
    },
    bDisabled: {
      type: Boolean,
      default: false
    },
    oValorField: {}
  },
  data() {
    return {
      valor: '',
      pruebaRef: null,
      noVacio: [
        val => (val && val.length > 0) || 'Please type something',
        val => !!val || 'Field is required'
      ],
      nameRef: null,
      aItemsData: {}
    }
  },
  mounted() {
    this.aItemsData = this.oValorField;
  },
  methods: {
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();

      month = month < 10 ? `0${month}` : month;
      day = day < 10 ? `0${day}` : day;

      return `${year}-${month}-${day}`;
    },
  },
  watch: {
    valor(newValor) {
      this.$emit('updateValor', newValor)
    },

    value(newValue) {
      this.valor = newValue
    },

    oValorField(newoValorField) {
      this.valor = newoValorField[`${this.sKeyField}`]
    },

    aItemsData(newV) {
      if(newV) {
        this.valor = newV[`${this.sKeyField}`] ?? this.getCurrentDate()
      }
    },
  }
}
</script>