const state = () => ({
	aSystems: [],
})

const getters = {
	onGetSystems: (state) => {
		return state.aSystems
	}
} 

const mutations = {
	SYSTEMS: (state, estado) => {
		state.aSystems = estado
	}
}

const actions = {
	onSetSystems({commit}, estado) {
		commit("SYSTEMS", estado)
	}
}


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}