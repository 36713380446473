import store from '@/store/index.js'
import { createRouter, createWebHistory } from 'vue-router'

import NotFound from '../views/PageNotFound.vue'
import LoginPage from '../views/LoginPage.vue'
import DashboardPage from '../views/DashboardPage.vue'
//CONFIG
import FirmasPage from '../views/Config/FirmasPage.vue'
import PersonasPage from '../views/Config/PersonasPage.vue'
import UsuariosPage from '../views/Config/UsuariosPage.vue'
import OutletsPage from '../views/Config/OutletsPage.vue'
import GuidesPage from '../views/Config/GuidesPage.vue'
import RoutesPage from '../views/Config/RoutesPage.vue'
import VehiclesPage from '../views/Config/VehiclesPage.vue'
//RECEPTION
import DailyPayrollPage from '../views/Reception/DailyPayrollPage.vue'
import IncomeFormPage from '../views/Reception/IncomeFormPage.vue'
import PendingSacrificesPage from '../views/Reception/PendingSacrificesPage.vue'
//DAILY ROUTES
import DailyRoutePage from '../views/PostMortem/DailyRoutePage.vue';
import BenefitOrderPage from '../views/PostMortem/BenefitOrderPage.vue';
import PostMortemInspectionPage from '../views/PostMortem/PostMortemInspectionPage.vue';
import ZeroGutsTolerance from '../views/PostMortem/ZeroGutsTolerancePage.vue';
import ZeroToleranceInspectionPage from '../views/PostMortem/ZeroToleranceInspectionPage.vue';
import ChannelConditioningPage from '../views/PostMortem/ChannelConditioningPage.vue';
import VisceraDispathPage from '../views/PostMortem/VisceraDispathPage.vue';
import SeizureComparisonPage from '../views/PostMortem/SeizureComparisonPage.vue';
//ANTEMORTEM
import AntemortemInspectionPage from '../views/Antemortem/AntemortemInspectionPage.vue';
import ParturientFemalesPage from '../views/Antemortem/ParturientFemalesPage.vue';
import SuspiciousAnimalsPage from '../views/Antemortem/SuspiciousAnimalsPage.vue';
import EmergencyCoilEntryPage from '../views/Antemortem/EmergencyCoilEntryPage.vue';
import AntemortemInspectionSuspiciousAnimalsPage from '../views/Antemortem/AntemortemInspectionSuspiciousAnimalsPage.vue';
//DESPACHO
import DispatchGuidePage from '../views/Dispatch/DispatchGuidePage.vue';

//REPORTS
import ReportsPage from '../views/ReportsPage.vue';
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/admim',
    name: 'admin',
    component: DashboardPage
  },
  {
    path: '/personas',
    name: 'personas',
    component: PersonasPage
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: UsuariosPage
  },
  {
    path: '/expendios',
    name: 'expendios',
    component: OutletsPage
  },
  {
    path: '/guias',
    name: 'guias',
    component: GuidesPage
  },
  {
    path: '/rutas',
    name: 'rutas',
    component: RoutesPage
  },
  {
    path: '/vehiculos',
    name: 'vehiculos',
    component: VehiclesPage
  },
  {
    path: '/firmas',
    name: 'firmas',
    component: FirmasPage
  },
  // RECPECIÓN
  {
    path: '/planillaingreso',
    name: 'planillaingreso',
    component: IncomeFormPage
  },
  {
    path: '/planilladiaria',
    name: 'planilladiaria',
    component: DailyPayrollPage
  },
  {
    path: '/sacrificiospendientes',
    name: 'sacrificiospendientes',
    component: PendingSacrificesPage
  },
  // POST - MORTEM 
  {
    path: '/rutadiaria',
    name: 'rutadiaria',
    component: DailyRoutePage
  },
  {
    path: '/reportes',
    name: 'reportes',
    component: ReportsPage
  },
  {
    path: '/planillaordenbeneficio',
    name: 'planillaordenbeneficio',
    component: BenefitOrderPage
  },
  {
    path: '/inspeccionpostmortem',
    name: 'inspeccionpostmortem',
    component: PostMortemInspectionPage
  },
  {
    path: '/toleranciacerovisceras',
    name: 'toleranciacerovisceras',
    component: ZeroGutsTolerance
  },
  {
    path: '/inspeccioncerotolerancia',
    name: 'inspeccioncerotolerancia',
    component: ZeroToleranceInspectionPage
  },
  {
    path: '/acondicionamientodelacanal',
    name: 'acondicionamientodelacanal',
    component: ChannelConditioningPage
  },
  {
    path: '/despachovisceras',
    name: 'despachovisceras',
    component: VisceraDispathPage
  },
  {
    path: '/comparaciondecomisos',
    name: 'comparaciondecomisos',
    component: SeizureComparisonPage
  },
  // ANTE - MORTEM
  {
    path: '/inspeccionantemortem',
    name: 'inspeccionantemortem',
    component: AntemortemInspectionPage
  },
  {
    path: '/registrohembrasparidas',
    name: 'registrohembrasparidas',
    component: ParturientFemalesPage
  },
  {
    path: '/animalessospechosos',
    name: 'animalessospechosos',
    component: SuspiciousAnimalsPage
  },
  {
    path: '/ingresobovinosemergencia',
    name: 'ingresobovinosemergencia',
    component: EmergencyCoilEntryPage
  },
  {
    path: '/inspeccionantemortemsospechosos',
    name: 'inspeccionantemortemsospechosos',
    component: AntemortemInspectionSuspiciousAnimalsPage
  },
  {
    path: '/guiadespacho',
    name: 'guiadespacho',
    component: DispatchGuidePage
  },

  
  
  
  { path: '/:pathMatch(.*)', name: 'not-found', component: NotFound },
]
const router = createRouter({
  history: createWebHistory(`${process.env.BASE_URL}`),
  routes
})


// interceptor para validar que se este logueado
router.beforeEach ((to, from, next) => {
  // console.log(to)
  // console.log(from)
  if(to.query.t != undefined && to.query.ip != undefined){
    // console.log("query","ingreso validacion exitosa", to.query)
    let sToken = to.query.t
    let sIP = to.query.ip
    store.dispatch('onSetLogin', true)
    store.dispatch('onSetToken', sToken)
    sessionStorage.ip = sIP
  }
  
  switch (to.name) {
    case "login":
    case "agregar":
    case "twoauth": {
      next();
      break;
    }
    default: {
      if (!store.state.bLogin) {
        next('/');
      } else {
        next();
      }
      break;
    }
  }
})


export default router
