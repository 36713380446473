<template>
    <q-dialog    
        v-model="bValor"
        persistent>
        <q-card>
            <q-bar>
                <div>{{ title }}</div>
                <q-space/>
                <q-btn @click="onClose" icon="close" dense flat/>
            </q-bar>
            <q-card-section>
                <div class="row justify-start q-col-gutter-x-xs">
                    <div class="containerModal" :class="`col-xs-12 col-sm-12 col-md-${field.nCols} col-lg-${field.nCols}`" v-for="field, nKey in dinamicFields" :key="nKey">
                        <component       
                        :is="field.sComponent"
                        :sKeyField="field.sName"
                        :sLabel=field.sLabel
                        :bRequired=field.bRequired
                        :bDisabled="field.bDisabled"
                        :sEndPoint="field.sEndPoint"
                        :oValorField="oValorField"
                        :aSubComponents="field.aSubComponents"
                        @updateValor="onGetValuesForm(field.sName, $event)">
                        </component>
                        <q-separator class="q-my-md" v-if="field.bSeparator" />
                    </div>
                </div>
            </q-card-section>
            <q-card-actions align="between">
                <q-btn style="min-width: 120px;" label="Cancelar" @click="onClose" class="bg-negative" text-color="white"/>
                <q-btn style="min-width: 120px;" label="Descargar" @click="onDownloadForm" :disable="isLoading" :loading="isLoading"  class="bg-primary" text-color="white"/>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'

import FormText from '@/components/Fields/FormText'
import FormNumber from '@/components/Fields/FormNumber'
import FormEmail from '@/components/Fields/FormEmail'
import FormPassword from '@/components/Fields/FormPassword'
import FormSelect from '@/components/Fields/FormSelect'
import FormMultiSelect from '@/components/Fields/FormMultiSelect'
import FormSearchSelect from '@/components/Fields/FormSearchSelect'
import FormFileImage from '@/components/Fields/FormFileImage.vue';
import FormFile from '@/components/Fields/FormFile.vue';
import FormDate from '@/components/Fields/FormDate.vue';
import FormTime from '@/components/Fields/FormTime.vue';
import DailyPayrollTable from '@/components/Specials/DailyPayrollTable.vue';

export default {
    name: 'GenericForm',
    components: {
        FormText,
        FormNumber,
        FormEmail,
        FormPassword,
        FormSelect,
        FormMultiSelect,
        FormSearchSelect,
        FormFileImage,
        FormFile,
        FormDate,
        FormTime,
        DailyPayrollTable
    },
    props: {
        value: {},
        fields: {},
        editId: {
            type: Number,
            default: null
        },
        isFormdata: {
            type: Boolean,
            default: false,
        },
        formatName: {
            type: String,
            default: '',
        },
        endpoint: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
            bValor: this.value,
            dinamicFields: [],
            oFormField: {},
            oValorField: {},
        }
    },
    methods: {
        onClose() {
            this.$emit("onClose")
        },
        onGetValuesForm(index, data) {
            // If data size is set this is file
            if(data && data.size) {
                this.oFormField[index] = data;
            } else {
                this.oFormField[index] = (Array.isArray(data)) ? data : (typeof data == 'object') ? data.id : data
            }

            if(this.dinamicFields.find(oComponent => oComponent.sDependsOn === index)) {
                const nIndex = this.dinamicFields.findIndex(oComponent => oComponent.sDependsOn === index);
                this.dinamicFields[nIndex].sEndPoint = `${this.fields[nIndex].sEndPoint}/${this.oFormField[index]}`;
            }
        },
        async onGetDataForm() {
            this.dinamicFields = this.fields.map(oComponent => {
                return {
                    ...oComponent, 
                    sEndPoint: typeof oComponent.sEndPoint == 'object' ? oComponent.sEndPoint : (oComponent.sEndPoint ? `${oComponent.sEndPoint}${oComponent.sDependsOn ? `/${this.oValorField[oComponent.sDependsOn]}` : ''}` : oComponent.sEndPoint )
                }
            });
        },
        async onDownloadForm() {
            this.isLoading = true;
            const aResponse = await axiosServices.onAxiosPostToFile(`${this.endpoint}Format`, this.oFormField)
            if(aResponse.status === 200) {
                const url = window.URL.createObjectURL(new Blob([aResponse.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.formatName}.xlsx`);
                document.body.appendChild(link);
                link.click();
                // this.$emit('onClose');
            } else if(aResponse.status == 404) {
                this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: 'No se encontraron registros'
                })
            } else {
                this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: '¡Complete todos los campos!'
                })
            }
            this.isLoading = false;
        },
    },
    mounted() {
        if(this.endpoint) {
            this.onGetDataForm();
        }
    }
}
</script>