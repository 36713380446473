<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
        :isMaximized="true"
        :hideID="true"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "AntemortemInspectionPage",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Inspección Antemortem',
        endpoint: 'antemortemInspection',
        fields: [
            {
                sName: 'date_to_guide',
                sLabel: 'Fecha',
                sComponent: 'FormDate',
                sEndPoint: '',
                nCols: 6,
                sVisible: 'create'
            },
            {
                sName: 'date',
                sLabel: 'Fecha Antemortem',
                sComponent: 'FormDate',
                sEndPoint: '',
                nCols: 6
            },
            {
                sName: 'id_guide',
                sLabel: 'Guía',
                sComponent: 'FormSelect',
                sEndPoint: 'sltGuideThroughMaster/antemortem_inspection',
                sDependsOn: 'date_to_guide',
                nCols: 12,
                sVisible: 'create',
            },
            {
                sName: 'guide',
                sLabel: 'Guía',
                sComponent: 'FormText',
                sVisible: 'edit',
                bDisabled: true,
                nCols: 6
            },
            {
                sName: 'entries',
                sComponent: 'AntemortemInspectionTable',
                sEndPoint: 'sltPayrrollsGuide/antemortemInspections',
                sDependsOn: 'id_guide',
                nCols: 12,
                aSubComponents: [
                    {
                        sName: 'code',
                        sLabel: '# Animal',
                        sComponent: 'FormText',
                        sWidth: '100px',
                        bDisabled: true,
                    },
                    {
                        sName: 'corral_number',
                        sLabel: 'Número corral',
                        sComponent: 'FormNumber',
                        sEndPoint: '',
                    },
                    
                    {
                        sName: 'corral_entry',
                        sLabel: 'Ingreso Ante Mortem',
                        sComponent: 'FormTime',
                    },
                    {
                        sName: 'rest_time',
                        sLabel: 'Tiempo de descanso',
                        sComponent: 'FormText',
                    },
                    {
                        sName: 'findings_and_observations',
                        sLabel: 'Hallazgos y observaciones',
                        sComponent: 'FormArea',
                    },
                    {
                        sName: 'final_dictament',
                        sLabel: 'Dictamen final',
                        sComponent: 'FormArea',
                    },
                    {
                        sName: 'cause_for_seizure',
                        sLabel: 'Causa de decomiso',
                        sComponent: 'FormArea',
                    },
                ]
            },
        ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>