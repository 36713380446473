<template>
  <q-file
    class="q-mb-sm"
    v-model="valor"
    :rules="noVacio"
    :accept="oAccept"
    use-chips
    :label="`${sLabel} (${oAccept})${bRequired ? ' *' : ''}`"
    filled
    dense>
  </q-file>
  <p
    v-show="sFile"
    @click="onDownloadFile"
    class="q-link cursor-pointer text-primary q-hoverable q-mb-lg"
  >
    {{ sFile }}
  </p>
</template>
<script>  
import axiosServices from '@/services/axiosServices'
export default{
name: 'FormFile',
props: {
  value: {},
  sKeyField: {
    type: String,
    default: ''
  },
  sLabel: {
    type: String,
    default: ''
  },
  sEndPoint: {
    type: String,
    default: ''
  },
  oValorField: {}
},
data() {
  return {
    valor: '',
    sFile: '',
    bLoading: false,
    noVacio: [v => !!v],
    oAccept: '.pdf, .docx'
  }
},
methods: {
  async onDownloadFile() {
    this.bLoading = true;
    const response = await axiosServices.onAxiosGetFile(`${this.sEndPoint}/${this.oValorField.id}`, 'blob');
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    
    const disposition = response.headers['content-disposition'];
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
    const fileName = matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : this.sFile;

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    // Libera recursos
    window.URL.revokeObjectURL(link.href);
    this.bLoading = false;
  }
},
async mounted() {
  if(Object.keys(this.oValorField).length !== 0) {
    this.sFile = this.oValorField[`${this.sKeyField}`];
  }
},
watch: {
  valor(newValor) {
    this.$emit('updateValor', newValor)
  },
  value(newValue) {
    this.valor = newValue
  },
}

}

</script>