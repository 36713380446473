<template>
  <div class="container q-mx-md q-my-lg">
    <q-card bordered>
      <q-card-section>
        <q-carousel
          height="70vh"
          animated
          v-model="slide"
          arrows
          navigation
          infinite
        >
          <q-carousel-slide :name="1" img-src="../assets/img/slider/slide-1.jpeg" />
          <q-carousel-slide :name="2" img-src="../assets/img/slider/slide-2.jpeg" />
          <q-carousel-slide :name="3" img-src="../assets/img/slider/slide-3.jpeg" />
          <q-carousel-slide :name="4" img-src="../assets/img/slider/slide-4.jpeg" />
        </q-carousel>
      </q-card-section>
    </q-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
// import axiosServices from '@/services/axiosServices';
export default {
  name: "DashboardPage",
  components: {},
  data() {
    return {
      slide: 1
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>