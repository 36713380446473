<template>
  <div class="q-mb-sm">
    <q-input 
      filled  
      dense 
      v-model="valor" 
      type="email" 
      :disable="bDisabled"
      :label="`${sLabel}${bRequired ? ' *' : ''}`" 
      :rules="noVacio" lazy-rules 
    />
  </div>
</template>

<script>

export default {
  name: "FormEmail",
  emits: ['updateValor'],
  props: {
    value: {},
    sKeyField: {
      type: String,
      default: ''
    },
    sLabel: {
      type: String,
      default: ''
    },
    bDisabled: {
      type: Boolean,
      default: false
    },
    bRequired: {
      type: Boolean,
      default: false
    },
    oValorField: {}
  },
  data() {
    return {
      valor: '',
      pruebaRef: null,
      noVacio: [
        v => !!v || 'El campo no puede ser vacio.',
        v => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(v) || 'Formato de correo invalido.'
      ],
      nameRef: null,
      aItemsData: {}
    }
  },
  mounted() {
    this.aItemsData = this.oValorField;
  },
  watch: {
    valor(newValor) {
      this.$emit('updateValor', newValor)
    },

    value(newValue) {
      this.valor = newValue
    },

    oValorField(newoValorField) {
      this.valor = newoValorField[`${this.sKeyField}`]
    },

    aItemsData(newV) {
      this.valor = newV[`${this.sKeyField}`]
    },
  }
}
</script>