<template>
  <div class="container q-mx-md q-my-lg">
    <DefaultGrid
      :title="title"
      :endpoint="endpoint"
      :isFormdata="true"
      :permissions="permissions"
      :fields="fields"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
export default {
  name: "UsuariosPage",
  components: {
    DefaultGrid
  },
  data() {
    return {
      title: 'Usuarios',
      endpoint: 'users',
      fields: [
        {
            sName: 'id_person',
            sLabel: 'Persona',
            sComponent: 'FormSelect',
            sEndPoint: 'sltPersons',
            nCols: 12
        },
        {
            sName: 'login',
            sLabel: 'Login',
            sComponent: 'FormText',
            nCols: 8
        },
        {
            sName: 'id_charge',
            sLabel: 'Cargo',
            sComponent: 'FormSelect',
            nCols: 4,
            sEndPoint: 'sltCharges',
    
        },
        {
            sName: 'password',
            sLabel: 'Password',
            sComponent: 'FormPassword',
            sVisible: 'create',
            nCols: 12
        },
      ]
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>