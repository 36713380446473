
<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :isFormdata="true"
        :isMaximized="true"
        :hideID="true"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "DailyPayrollPage",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Planilla diaria',
        endpoint: 'dailyPayroll',
        fields: [
                {
                    sName: 'date',
                    sLabel: 'Fecha',
                    sComponent: 'FormDate',
                    nCols: 12
                },
                {
                    sName: 'guide',
                    sLabel: 'Guía',
                    sComponent: 'FormText',
                    sVisible: 'edit',
                    bDisabled: true,
                    nCols: 12
                },
                {
                    sName: 'id_dp_master',
                    sLabel: 'Guía',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltGuideForDailyPayroll',
                    sVisible: 'create',
                    sDependsOn: 'date',
                    nCols: 12
                },
                {
                    sName: 'entries',
                    sComponent: 'DailyPayrollTable',
                    sEndPoint: 'dailyPayrollGuides',
                    sDependsOn: 'id_dp_master',
                    bEnablePending: true,
                    nCols: 12,
                    aSubComponents: [
                        {
                            sName: 'code',
                            sLabel: 'Código',
                            sComponent: 'FormText',
                            sWidth: '100px',
                            bDisabled: true,
                        },
                        {
                            sName: 'id_product_type',
                            sLabel: 'Tipo de producto',
                            sComponent: 'FormSelect',
                            sEndPoint: 'sltProductTypes',
                            sWidth: 'auto'
                        },
                        {
                            sName: 'sacrifice_date',
                            sLabel: 'Fecha de sacrificio',
                            sComponent: 'FormDate',
                            sWidth: 'auto'
                        },
                        {
                            sName: 'id_outlet',
                            sLabel: 'N° Expendio',
                            sComponent: 'FormSelect',
                            sEndPoint: 'sltOutlets',
                            sWidth: 'auto'
                        },
                        {
                            sName: 'number',
                            sLabel: 'Número',
                            sComponent: 'FormNumber',
                            sWidth: 'auto'
                        },
                        {
                            sName: 'special_order',
                            sLabel: 'Orden Especial',
                            sComponent: 'FormArea',
                            sWidth: 'auto'
                        },
                    ]
                }
            ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>