const state = () => ({
	menu: false,
	module: 'SIG',
  nIdModule: 1,
  nIdFeature: 1
})


const getters = {
	onGetMenu: (state) => {
		return state.menu
	},
	onGetModule: (state) => {
		return state.module
	},
  onGetIdModule: (state) => {
		return state.nIdModule
	},
  onGetIdFeature: (state) => {
		return state.nIdFeature
	}
} 

const mutations = {
	MENU: (state, estado) => {
		state.menu = estado
	},
	MODULO: (state, estado) => {
		state.module = estado
	},
  IDMODULO: (state, estado) => {
		state.nIdModule = estado
	},
  IDFEATURE: (state, estado) => {
		state.nIdFeature = estado
	}
}

const actions = {
	onShowMenu({commit}, estado) {
		commit("MENU", estado)
	},
	onShowModule({commit}, estado) {
		commit("MODULO", estado)
	},
  onSetIdModule({commit}, estado) {
		commit("IDMODULO", estado)
	},
  onSetIdFeature({commit}, estado) {
		commit("IDFEATURE", estado)
	}
}


export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}