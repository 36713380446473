<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "ZeroToleranceInspection",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Inspección Cero Tolerancia',
        endpoint: 'zeroToleranceInspection',
        fields: [
            {
                sName: 'date',
                sLabel: 'Fecha',
                sComponent: 'FormDate',
                nCols: 12
            },
            {
              sName: 'id_outlet',
              sLabel: 'Expendio',
              sComponent: 'FormSelect',
              sEndPoint: 'sltAntemoremOutlets/zeroToleranceInspection',
              sVisible: 'create',
              nCols: 6
            },
            {
              sName: 'outlet',
              sLabel: 'Expendio',
              sComponent: 'FormText',
              bDisabled: true,
              sVisible: 'edit',
              nCols: 6,
            },
            {
              sName: 'id_daily_payroll',
              sLabel: '# Animal',
              sComponent: 'FormSelect',
              sEndPoint: 'sltAntemoremAnimals/zeroToleranceInspection',
              sDependsOn: 'id_outlet',
              sVisible: 'create',
              nCols: 6
            },
            {
              sName: 'code',
              sLabel: '# Animal',
              sComponent: 'FormText',
              bDisabled: true,
              sVisible: 'edit',
              nCols: 6,
            },
            {
                sName: 'milk',
                sLabel: 'Leche',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'fecal_matter',
                sLabel: 'M. Fecal',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'rumen_content',
                sLabel: 'Contenido Rumial',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'corrective_actions',
                sLabel: 'Acciones Correctivas',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'quantity',
                sLabel: 'Cantidad',
                sComponent: 'FormNumber',
                nCols: 12
            },
        ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>