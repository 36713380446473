<template>
  <div class="container">
    
  </div>
</template>
<script>
export default {
  name: "PageNotFound",
  data() {
    return {
    }
  }
}
</script>