<template>
  <div class="q-mb-sm">
    <q-input dense filled  v-model="valor" :type="bShowPass ? 'text' : 'password'" :label="`${sLabel}${bRequired ? ' *' : ''}`" lazy-rules>
      <template v-slot:append>
        <q-icon
          :name="bShowPass ? 'visibility_off' : 'visibility'"
          @click="bShowPass = !bShowPass"/>
        </template>
    </q-input>
  </div>
</template>

<script>

export default {
  name: "FormText",
  emits: ['updateValor'],
  props: {
    value: {},
    sKeyField: {
      type: String,
      default: ''
    },
    sLabel: {
      type: String,
      default: ''
    },
    bRequired: {
      type: Boolean,
      default: false
    },
    oValorField: {}
  },
  data() {
    return {
      valor: '',
      bShowPass: false,
      pruebaRef: null,
      noVacio: [
        val => (val && val.length > 0) || 'Please type something',
        val => !!val || 'Field is required'
      ],
      nameRef: null,
      aItemsData: {}
    }
  },
  mounted() {
    this.aItemsData = this.oValorField;
  },
  watch: {
    valor(newValor) {
      this.$emit('updateValor', newValor)
    },

    value(newValue) {
      this.valor = newValue
    },

    oValorField(newoValorField) {
      this.valor = newoValorField[`${this.sKeyField}`]
    },

    aItemsData(newV) {
      this.valor = newV[`${this.sKeyField}`]
    },
  }
}
</script>