<template>
  <div class="preview" v-if="bLoadedPreview">
    <img :src="sUrlPreview" alt="preview" />
  </div>
  <q-file
    class="q-pb-none q-mb-sm"
    v-model="valor"
    :rules="noVacio"
    :accept="oAccept"
    :label="`${sLabel} (${oAccept})${bRequired ? ' *' : ''}`"
    filled
    dense
  >
    <template v-slot:prepend>
      <q-icon name="insert_drive_file" @click.stop.prevent />
    </template>
    <template v-slot:append>
      <q-icon name="close" @click.stop.prevent="valor = null" v-show="valor" class="cursor-pointer" />
    </template>
  </q-file>
</template>
<script>
import axiosServices from '@/services/axiosServices';
export default {
  name: "FormFile",
  emits: ['updateValor'],
  props: {
    value: {},
    sKeyField: {
      type: String,
      default: "",
    },
    sLabel: {
      type: String,
      default: "",
    },
    sEndPoint: {
      type: String,
      default: ''
    },
		bLocalEndPoint: {
      type: Boolean
    },
    oValorField: {},
  },
  data() {
    return {
      valor: "",
      noVacio: [(v) => !!v],
      bLoadedPreview: false,
      sUrlPreview: "",
      oAccept: ".png, .jpg, .jpeg",
    };
  },
  async mounted(){
    this.bLoadedPreview = false;
    if(Object.keys(this.oValorField).length !== 0 && this.oValorField[this.sKeyField]) {
      const oResponse = await axiosServices.onAxiosGet(`/${this.sEndPoint}/${this.oValorField.id}`)
      this.sUrlPreview = oResponse.data;
      this.bLoadedPreview = true;
    }
  },
  watch: {
    valor(newValor) {
      if(!newValor) {
        this.bLoadedPreview = false;
        this.sUrlPreview = "";
        return;
      }
      this.bLoadedPreview = false;
      this.$emit("updateValor", newValor);
      var that = this;
      var reader = new FileReader();
      reader.readAsDataURL(newValor);
      reader.onload = function () {
        that.bLoadedPreview = true;
        that.sUrlPreview = this.result;
      };
    },
    value(newValue) {
      this.valor = newValue;
    },
  },
};
</script>
<style scoped>
.preview {
  background: #f0f0f0;
}
.preview img {
  max-width: 100%;
  max-height: 150px;
  display: block;
  margin: 0 auto;
}
</style>