<template>
  <div class="container q-mx-md q-my-lg">
    <DefaultGrid
      :title="title"
      :endpoint="endpoint"
      :isFormdata="true"
      :permissions="permissions"
      :fields="fields"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
export default {
  name: "VehiclesPage",
  components: {
    DefaultGrid
  },
  data() {
    return {
      title: 'Vehiculos',
      endpoint: 'vehicles',
      fields: [
        {
            sName: 'plate',
            sLabel: 'Placa',
            sComponent: 'FormText',
            nCols: 6
        },
        {
            sName: 'driver_name',
            sLabel: 'Nombre del conductor',
            sComponent: 'FormText',
            nCols: 6
        },
        {
            sName: 'driver_document',
            sLabel: 'Documento del conductor',
            sComponent: 'FormText',
            nCols: 12
        },
        {
            sName: 'refrigerated',
            sLabel: 'Refrigerado',
            sComponent: 'FormSelect',
            aOptions: [
              { id: 'Si', name: 'Si' },
              { id: 'No', name: 'No' }
            ],
            nCols: 6
        },
        {
            sName: 'isothermal',
            sLabel: 'Isotermo',
            sComponent: 'FormSelect',
            aOptions: [
              { id: 'Si', name: 'Si' },
              { id: 'No', name: 'No' }
            ],
            nCols: 6
        },
        {
            sName: 'temperature',
            sLabel: 'Temperatura',
            sComponent: 'FormNumber',
            nCols: 12
        },
      ]
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>