<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "DailyPayrollPage",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Rutas de vehículos diaria',
        endpoint: 'dailyRoutes',
        fields: [
                {
                    sName: 'id_route',
                    sLabel: 'Ruta',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltRoutes',
                    nCols: 12
                },
                {
                    sName: 'id_outlet',
                    sLabel: 'Expendio',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltOutlets',
                    nCols: 6
                },
                {
                    sName: 'id_vehicle',
                    sLabel: 'Vehiculo',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltVehicles',
                    nCols: 6
                },
                {
                    sName: 'quantity',
                    sLabel: 'Cantidad',
                    sComponent: 'FormNumber',
                    nCols: 6
                },
                {
                    sName: 'date',
                    sLabel: 'Fecha',
                    sComponent: 'FormDate',
                    nCols: 6
                },
                {
                    sName: 'orders',
                    sLabel: 'Ordenes',
                    sComponent: 'FormArea',
                    nCols: 12
                },
            ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>