<template>
  <div class="row q-mb-sm"  v-if="valor" style="background: rgba(0, 0, 0, 0.05); min-width: 150px;">
    <div class="col-8">
      <p class="q-mb-none text-grey" v-text="fieldsText"></p>
    </div>
    <div class="col-4 row justify-center items-center">
      <q-icon class="cursor-pointer" :name="valor.success ? 'done' : 'dangerous'" :color="valor.success ? 'green' : 'warning'" size="32px">
        <q-tooltip>{{ valor.success ? 'CUMPLE INSPECCIÓN POST MORTEM' : 'NO COINCIDE INSPECCIÓN POST MORTEM' }}</q-tooltip>
      </q-icon>
    </div>
  </div>
  <div v-else class="q-mb-sm">
    <q-input 
      dense 
      filled   
      type="text" 
      :disable="bDisabled" 
      lazy-rules 
    />
  </div>
</template>

<script>
import translate from "@/services/translate";

export default {
  name: "FormComparison",
  emits: ['updateValor'],
  props: {
    value: {
      default: null
    },
    sKeyField: {
      type: String,
      default: ''
    },
    sLabel: {
      type: String,
      default: ''
    },
    bRequired: {
      type: Boolean,
      default: false
    },
    bDisabled: {
      type: Boolean,
      default: false
    },
    oValorField: {}
  },
  data() {
    return {
      valor: '',
      fieldsText: '',
      pruebaRef: null,
      noVacio: [
        val => (val && val.length > 0) || 'Please type something',
        val => !!val || 'Field is required'
      ],
      nameRef: null,
      aItemsData: {}
    }
  },
  methods: {
    onTranslate(sText) {
      return translate.getTranslation(sText).toLocaleUpperCase()
    }
  },
  mounted() {
    this.aItemsData = this.oValorField;
  },
  watch: {
    valor(newValor) {
      if(newValor) {
        let fields = newValor.fields.map(field => this.onTranslate(field));
        this.fieldsText = fields.join(', ');
      }
      this.$emit('updateValor', newValor)
    },

    value(newValue) {
      this.valor = newValue
    },

    oValorField(newoValorField) {
      this.valor = newoValorField[`${this.sKeyField}`]
    },

    aItemsData(newV) {
      if(newV) {
        this.valor = newV[`${this.sKeyField}`]
      }
    },
  }
}
</script>