<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "VisceraDispathPage",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Despacho Visceras',
        endpoint: 'visceraDispatch',
        fields: [
        {
              sName: 'date',
              sLabel: 'Fecha',
              sComponent: 'FormDate',
              nCols: 12
          },
          {
              sName: 'id_outlet',
              sLabel: 'Expendio',
              sComponent: 'FormSelect',
              sEndPoint: 'sltAntemoremOutlets/visceraDispatch',
              sVisible: 'create',
              nCols: 6
            },
            {
              sName: 'outlet',
              sLabel: 'Expendio',
              sComponent: 'FormText',
              bDisabled: true,
              sVisible: 'edit',
              nCols: 6,
            },
            {
              sName: 'id_daily_payroll',
              sLabel: '# Animal',
              sComponent: 'FormSelect',
              sEndPoint: 'sltAntemoremAnimals/visceraDispatch',
              sDependsOn: 'id_outlet',
              sVisible: 'create',
              nCols: 6
            },
            {
              sName: 'code',
              sLabel: '# Animal',
              sComponent: 'FormText',
              bDisabled: true,
              sVisible: 'edit',
              nCols: 6,
            },
            {
                sName: 'head',
                sLabel: 'Cabeza',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'small_ints',
                sLabel: 'Ints. Delgado',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'large_ints',
                sLabel: 'Ints. Grueso',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'panolon',
                sLabel: 'Pañolon',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'rennet',
                sLabel: 'Cuajo',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'callus',
                sLabel: 'Callo',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'liver',
                sLabel: 'Hígado',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'lung',
                sLabel: 'Pulmones',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'legs',
                sLabel: 'Patas',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'hands',
                sLabel: 'Manos',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'udders',
                sLabel: 'Ubres',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'booklet',
                sLabel: 'Librillo',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'observations',
                sLabel: 'Observaciones',
                sComponent: 'FormArea',
                nCols: 12
            },
        ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>