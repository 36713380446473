<template>
    <q-card bordered>
        <q-card-section>
            <div class="text-h6" v-text="title"></div>
        </q-card-section>
        <q-card-section>
            <div class="row justify-start q-col-gutter-x-xs">
                <div class="containerModal" :class="`col-xs-12 col-sm-12 col-md-${field.nCols} col-lg-${field.nCols}`" v-for="field, nKey in dinamicFields" :key="nKey">
                    <component                
                        :is="field.sComponent"
                        :sKeyField="field.sNombreCampo"
                        :sLabel=field.sLabel
                        :bRequired=field.bRequired
                        :bDisabled="field.bDisabled"
                        :sEndPoint="field.sEndPoint"
                        :oValorField="oValorField"
                        @updateValor="onGetValuesForm(field.sNombreCampo, $event)">
                    </component>
                    <q-separator class="q-my-md" v-if="field.bSeparator" />
                </div>
            </div>
        </q-card-section>
        <q-card-actions>
            <q-btn :loading="isLoading" @click="onSaveForm" class="full-width" color="primary" label="Guardar cambios" />
        </q-card-actions>
    </q-card>
</template>
<script>
import axiosServices from '@/services/axiosServices'

import FormText from '@/components/Fields/FormText'
import FormNumber from '@/components/Fields/FormNumber'
import FormEmail from '@/components/Fields/FormEmail'
import FormPassword from '@/components/Fields/FormPassword'
import FormSelect from '@/components/Fields/FormSelect'
import FormSearchSelect from '@/components/Fields/FormSearchSelect'
export default {
    name: 'LocalForm',
    components: {
        FormText,
        FormNumber,
        FormEmail,
        FormPassword,
        FormSelect,
        FormSearchSelect
    },
    props: {
        value: {},
        fields: {},
        endpoint: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
            dinamicFields: [],
            oFormField: {},
            oValorField: {}
        }
    },
    methods: {
        async onGetData() {
            const response = await axiosServices.onAxiosGet(this.endpoint);
            this.oValorField = response.data;
        },
        onGetValuesForm(index, data) {
            // If data size is set this is file
            if(data && data.size) {
                this.oFormField[index] = data;
            } else {
                this.oFormField[index] = (Array.isArray(data)) ? data : (typeof data == 'object') ? data.id : data
            }
            if(this.dinamicFields.find(oComponent => oComponent.sDependsOn === index)) {
                const nIndex = this.dinamicFields.findIndex(oComponent => oComponent.sDependsOn === index);
                this.dinamicFields[nIndex].sEndPoint = `${this.fields[nIndex].sEndPoint}/${this.oFormField[index]}`;
            }
        },
        async onSaveForm() {
            this.isLoading = true;
            const response = await axiosServices.onAxiosPost(this.endpoint, this.oFormField);
            if(response.code === 200) {
                this.$emit('onSave')
                this.$q.notify({
                    type: 'positive',
                    position: 'bottom',
                    message: 'Registro guardado exitosamente',
                });
            } else {
                this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: Object.values(response.errors)[0]
                })
            }
            this.isLoading = false;
        },
    },
    mounted() {
        this.onGetData();
        this.dinamicFields = this.fields.map(oComponent => {
            return {
                ...oComponent, 
                sEndPoint: typeof oComponent.sEndPoint == 'object' ? oComponent.sEndPoint : (oComponent.sEndPoint ? `${oComponent.sEndPoint}${oComponent.sDependsOn ? `/${this.oValorField[oComponent.sDependsOn]}` : ''}` : oComponent.sEndPoint )
            }
        });
    }
}
</script>