<template>
    <q-layout view="lHh Lpr fff">
        <q-page class="window-height window-width row justify-center items-center"
            style="background: linear-gradient(rgba(225,245,255,.3), rgba(255, 235, 225, 0.3));">
            <div class="column q-pa-lg">
                <q-img
                    src="../assets/img/logo_with_name.png"
                    class="logoimage"
                />
            </div>
            <div class="column q-pa-lg">
                <q-card class="shadow-2" style="width:100%;" bordered>
                    <q-card-section>
                        <q-form class="q-px-sm q-pt-sm">
                            <q-input ref="login" square filled clearable v-model="login" type="text" lazy-rules
                                :rules="[this.required, this.short]" label="Usuario">
                                <template v-slot:prepend>
                                    <q-icon name="person" />
                                </template>
                            </q-input>
                            <q-input ref="password" square filled clearable v-model="password" :type="passwordFieldType"
                                lazy-rules :rules="[this.required, this.short]" label="Contraseña">
                                <template v-slot:prepend>
                                    <q-icon name="lock" />
                                </template>
                                <template v-slot:append>
                                    <q-icon :name="visibilityIcon" @click="switchVisibility"
                                        class="cursor-pointer" />
                                </template>
                            </q-input>
                        </q-form>
                    </q-card-section>

                    <q-card-actions class="q-px-lg">
                        <q-btn @click="onLogin" :loading="loading" unelevated size="lg" class="bg-red-9 full-width text-white" :label="btnLabel" />
                    </q-card-actions>
                    <q-card-section class="text-center q-pa-sm">
                        <!-- <p class="text-grey-6">¿Olvidaste tu contraseña?</p> -->
                    </q-card-section>
                </q-card>
            </div>

        </q-page>
    </q-layout>
</template>
<script>
import axiosServices from '@/services/axiosServices';
export default {
    components: {},
    data(){
        return {
            loading: false,
            nKey: 0,
            login: '',
            password: '',
            passwordFieldType: 'password',
            btnLabel: 'INICIAR SESIÓN',
            visibility: false,
            visibilityIcon: 'visibility',
        }
  },
    
   methods: {
    required (val) {
      return  (val && val.length > 0 || 'Este campo es requerido')
    },
    short(val) {
      return  (val && val.length > 3 || 'El texto debe contener mínimo 3 carácteres')
    },
    async onLogin () {
       if (this.register){
          this.$refs.login.validate()
          this.$refs.username.validate()
          this.$refs.password.validate()
          this.$refs.repassword.validate()
       } else {
          this.$refs.login.validate()
          this.$refs.password.validate()      
       }
      
        if (!this.register) 
        if (!this.$refs.login.hasError && (!this.$refs.password.hasError)) {
                this.loading = true;
                let { login, password } = this;
                const response = await axiosServices.onAxiosPost("auth/login", { login, password })
                if(response.code === 200) {
                    this.$store.dispatch('onSetLogin', true)
                    this.$store.dispatch('onSetToken', response.data.token)
                    this.$store.dispatch('onSetUser', response.data.user)
                    this.$router.replace({name: "admin"})
                } else {
                    this.$q.notify({
                        type: 'negative',
                        position: 'top-right',
                        message: 'Correo o contraseña incorrectas'
                    });
                }
                this.loading = false;
            }
    },
    switchVisibility() {
      this.visibility = !this.visibility
      this.passwordFieldType = this.visibility ? 'text' : 'password'
      this.visibilityIcon =  this.visibility ? 'visibility_off' : 'visibility'
    }
  }
}
</script>
<style lang="scss">
.logoimage {
    width: 90%;
    min-width: 200px;
}
</style>