<template>
    <q-dialog    
        v-model="bValor"
        :maximized="isMaximized"
        persistent>
        <q-card>
            <q-bar>
                <div>{{ title }}</div>
                <q-space/>
                <q-btn @click="onClose" icon="close" dense flat/>
            </q-bar>
            <q-card-section>
                <div v-show="isLoading" class="row justify-center">
                    <q-spinner-facebook size="3.2rem" color="grey" />
                </div>
                <div v-show="!isLoading" class="row justify-start q-col-gutter-x-xs">
                    <template v-for="field, nKey in dinamicFields" :key="nKey">
                        <div 
                            v-if="!field.sVisible || ((modeNew && field.sVisible == 'create') || (!modeNew && field.sVisible == 'edit'))"
                            class="containerModal" :class="`col-xs-12 col-lg-${field.nCols} col-sm-${field.nCols} col-md-${field.nCols}`"
                        >
                            <component       
                                :is="field.sComponent"
                                :sKeyField="field.sName"
                                :sLabel=field.sLabel
                                :bRequired=field.bRequired
                                :bDisabled="field.bDisabled"
                                :bClearable="field.bClearable"
                                :sEndPoint="field.sEndPoint"
                                :aOptions="field.aOptions"
                                :oFormField="oFormField"
                                :oValorField="oValorField"
                                :aSubComponents="field.aSubComponents"
                                :sSubDependsOn="field.sSubDependsOn"
                                :bEnablePending="field.bEnablePending"
                                :bModeNew="modeNew"
                                @updateValor="onGetValuesForm(field.sName, $event)" 
                            />
                            <q-separator class="q-my-md" v-if="field.bSeparator" />
                        </div>
                    </template>
                </div>
            </q-card-section>
            <q-card-actions align="between">
                <q-btn style="min-width: 120px;" label="Cancelar" @click="onClose" class="bg-negative" text-color="white"/>
                <q-btn v-if="modeNew" style="min-width: 120px;" label="Registrar" @click="onSaveForm" :disable="isDisable || isLoading" :loading="isDisable"  class="bg-primary" text-color="white"/>
                <q-btn v-else style="min-width: 120px;" label="Actualizar" @click="onUpdateForm" :disable="isDisable || isLoading" :loading="isDisable"  class="bg-primary" text-color="white"/>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'

import FormArea from '@/components/Fields/FormArea'
import FormText from '@/components/Fields/FormText'
import FormNumber from '@/components/Fields/FormNumber'
import FormEmail from '@/components/Fields/FormEmail'
import FormPassword from '@/components/Fields/FormPassword'
import FormSelect from '@/components/Fields/FormSelect'
import FormMultiSelect from '@/components/Fields/FormMultiSelect'
import FormSearchSelect from '@/components/Fields/FormSearchSelect'
import FormFileImage from '@/components/Fields/FormFileImage.vue';
import FormFile from '@/components/Fields/FormFile.vue';
import FormDate from '@/components/Fields/FormDate.vue';
import FormTime from '@/components/Fields/FormTime.vue';
import DailyPayrollTable from '@/components/Specials/DailyPayrollTable.vue';
import DispatchGuideTable from '@/components/Specials/DispatchGuideTable.vue';
import AntemortemInspectionTable from '@/components/Specials/AntemortemInspectionTable.vue'
export default {
    name: 'GenericForm',
    components: {
        FormArea,
        FormText,
        FormNumber,
        FormEmail,
        FormPassword,
        FormSelect,
        FormMultiSelect,
        FormSearchSelect,
        FormFileImage,
        FormFile,
        FormDate,
        FormTime,
        DailyPayrollTable,
        DispatchGuideTable,
        AntemortemInspectionTable
    },
    props: {
        value: {},
        fields: {},
        editId: {
            type: Number,
            default: null
        },
        isFormdata: {
            type: Boolean,
            default: false,
        },
        isMaximized: {
            type: Boolean,
            default: false,
        },
        isDisable: {
            type: Boolean,
            default: false,
        },
        modeNew: {
            type: Boolean,
            default: false,
        },
        endpoint: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
            bValor: this.value,
            dinamicFields: [],
            oFormField: {},
            oValorField: {},
        }
    },
    methods: {
        onClose() {
            this.$emit("onClose", false)
        },
        onGetValuesForm(index, data) {
            // If data size is set this is file
            if(data && data.size) {
                this.oFormField[index] = data;
            } else {
                this.oFormField[index] = (Array.isArray(data)) ? data : (typeof data == 'object') ? data?.id : data
            }
            if(this.dinamicFields.find(oComponent => oComponent.sDependsOn === index)) {
                const nIndex = this.dinamicFields.findIndex(oComponent => oComponent.sDependsOn === index);
                this.dinamicFields[nIndex].sEndPoint = `${this.fields[nIndex].sEndPoint}/${this.oFormField[index]}`;
            }
            console.log(this.oFormField);
        },
        async onGetDataForm() {
            this.isLoading = true;
            if (this.modeNew === false) {
                const response = await axiosServices.onAxiosGet(`${this.endpoint}/${this.editId}`)
                this.oValorField = response.data;
            }
            let dinamicFields = this.fields.map(oComponent => {
                return {
                    ...oComponent, 
                    sEndPoint: typeof oComponent.sEndPoint == 'object' ? oComponent.sEndPoint : (oComponent.sEndPoint ? `${oComponent.sEndPoint}${oComponent.sDependsOn ? `/${this.oValorField[oComponent.sDependsOn]}` : ''}` : oComponent.sEndPoint )
                }
            });

            let aEndPoints = [];
            aEndPoints = dinamicFields.filter(oComponent => oComponent.sEndPoint);
            aEndPoints = aEndPoints.map(oComponent => {return oComponent.sEndPoint});

            let uniquedata = new Set(aEndPoints);
            let endpoints = Array.from(uniquedata);

            let axiosData = await this.onSetEndPointsData(endpoints);

            let mapEndPoints = {};
            endpoints.forEach((endpoint, key) => {
                mapEndPoints[endpoint] = axiosData[key];
            });

            this.dinamicFields = dinamicFields.map(oComponent => {
                return {
                    ...oComponent,
                    aOptions: oComponent.aOptions?.length ? oComponent.aOptions : mapEndPoints[oComponent.sEndPoint] ?? null,
                }
            })
            this.isLoading = false;
        },
        async onSetEndPointsData(data) {
            return new Promise((resolve, reject) => {
                Promise.all(data.map((element) => {
                    return axiosServices.onAxiosGet(element)
                        .then(response => response)
                        .catch(error => Promise.reject(error));
                }))
                .then(axiosData => resolve(axiosData))
                .catch(error => reject(error));
            });
        },
        async onSaveForm() {
            const areEmptyFields = await this.onCheckRequiredFields();
            if(areEmptyFields) {
                return this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: 'Por favor completa todos los campos requeridos (*)',
                });
            }
            const response = this.isFormdata 
                ? await axiosServices.onAxiosPostWithfile(this.endpoint, this.oFormField)
                : await axiosServices.onAxiosPost(this.endpoint, this.oFormField);
            if(response.code === 200) {
                this.$emit('onSave')
                return this.$q.notify({
                    type: 'positive',
                    position: 'bottom',
                    message: 'Registro realizado exitosamente',
                });
            } else {
                return this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: Object.values(response.errors)[0]
                })
            }
        },
        async onUpdateForm() {
            const areEmptyFields = await this.onCheckRequiredFields();
            if(areEmptyFields) {
                return this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: 'Por favor completa todos los campos requeridos (*)',
                });
            }

            const response =  this.isFormdata 
                ? await axiosServices.onAxiosPostWithfile(`${this.endpoint}/${this.editId}?_method=PUT`, this.oFormField)
                : await axiosServices.onAxiosPut(`${this.endpoint}/${this.editId}`, this.oFormField) ;
            if(response.code === 200) {
                this.$emit('onSave')
                return this.$q.notify({
                    type: 'positive',
                    position: 'bottom',
                    message: 'Registro actualizado exitosamente',
                });
            } else {
                return this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: Object.values(response.errors)[0]
                })
            }
        },
        async onCheckRequiredFields() {
            let nEmptyFields = 0;
            this.fields.forEach(field => {
                if(field.bRequired && !this.oFormField[field.sName]) {
                    nEmptyFields += 1;
                }
            })
            return nEmptyFields >= 1;
        },
    },
    mounted() {
        this.onGetDataForm();
    }
}
</script>