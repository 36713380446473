<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "ZeroGutsTolerance",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Tolerancia cero visceras',
        endpoint: 'zeroGutsTolerance',
        fields: [
            {
                sName: 'date',
                sLabel: 'Fecha',
                sComponent: 'FormDate',
                nCols: 6
            },
            {
                sName: 'id_specie',
                sLabel: 'Especie',
                sComponent: 'FormSelect',
                sEndPoint: 'sltSpecies',
                nCols: 6
            },
            {
              sName: 'id_outlet',
              sLabel: 'Expendio',
              sComponent: 'FormSelect',
              sEndPoint: 'sltAntemoremOutlets/zeroGutsTolerance',
              sVisible: 'create',
              nCols: 6
            },
            {
              sName: 'outlet',
              sLabel: 'Expendio',
              sComponent: 'FormText',
              bDisabled: true,
              sVisible: 'edit',
              nCols: 6,
            },
            {
              sName: 'id_daily_payroll',
              sLabel: '# Animal',
              sComponent: 'FormSelect',
              sEndPoint: 'sltAntemoremAnimals/zeroGutsTolerance',
              sDependsOn: 'id_outlet',
              sVisible: 'create',
              nCols: 6
            },
            {
              sName: 'code',
              sLabel: '# Animal',
              sComponent: 'FormText',
              bDisabled: true,
              sVisible: 'edit',
              nCols: 6,
            },
            {
                sName: 'organ',
                sLabel: 'Organo',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'fecal_matter',
                sLabel: 'M. Fecal',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'resume',
                sLabel: 'Resumen',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'hide',
                sLabel: 'Piel',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'hair',
                sLabel: 'Pelo',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'hem',
                sLabel: 'HEM',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'abscess',
                sLabel: 'Abseso',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'parasite',
                sLabel: 'Parásito',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'others',
                sLabel: 'Otros',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'correction',
                sLabel: 'Corrección',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'quantity',
                sLabel: 'Cantidad',
                sComponent: 'FormNumber',
                nCols: 12
            },
            {
                sName: 'observations',
                sLabel: 'Observaciones',
                sComponent: 'FormArea',
                nCols: 12
            },
        ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>