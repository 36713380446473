
<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :isFormdata="true"
        :isMaximized="true"
        :hideID="true"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "IncomeFormPage",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Planilla ingreso',
        endpoint: 'incomeForm',
        fields: [
                {
                    sName: 'date',
                    sLabel: 'Fecha',
                    sComponent: 'FormDate',
                    nCols: 12
                },
                {
                    sName: 'guide',
                    sLabel: 'Guía',
                    sComponent: 'FormText',
                    sVisible: 'edit',
                    bDisabled: true,
                    nCols: 12
                },
                {
                    sName: 'id_guide',
                    sLabel: 'Guía',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltGuideForIncomeForm',
                    sVisible: 'create',
                    sDependsOn: 'date',
                    nCols: 12
                },
                {
                    sName: 'entries',
                    sComponent: 'DailyPayrollTable',
                    sEndPoint: 'guides',
                    sDependsOn: 'id_guide',
                    nCols: 12,
                    aSubComponents: [
                        {
                            sName: 'code',
                            sLabel: 'Código',
                            sComponent: 'FormText',
                            sWidth: 'auto',
                            bDisabled: true,
                        },
                        {
                            sName: 'id_color',
                            sLabel: 'Color',
                            sComponent: 'FormSelect',
                            sEndPoint: 'colors',
                            sWidth: 'auto'
                        },
                        {
                            sName: 'id_gender',
                            sLabel: 'Sexo',
                            sComponent: 'FormSelect',
                            sEndPoint: 'genders',
                            sWidth: 'auto'
                        },
                        {
                            sName: 'id_age',
                            sLabel: 'Edad',
                            sComponent: 'FormSelect',
                            sEndPoint: 'sltAges',
                            sWidth: 'auto'
                        },
                        {
                            sName: 'id_purpose',
                            sLabel: 'Propósito',
                            sComponent: 'FormSelect',
                            sEndPoint: 'sltPurposes',
                            sWidth: 'auto'
                        },
                    ]
                }
            ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>