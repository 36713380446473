<template>
    <div class="flex justify-center" v-if="bLoading">
        <q-spinner color="grey" size="3em"/>
    </div>
    <template v-else >
    <div style="overflow-x: scroll;"  v-show="aAnimals.length">
        <table class="full-width form-table">
            <thead>
                <tr>
                    <th v-show="this.bModeNew">Enviar</th>
                    <th v-for="oComponent, key in aEditedComponents" :key="key" v-text="oComponent.sLabel" :style="{width: oComponent.sWidth}" />
                </tr>
            </thead>
            <tbody>
                <tr v-for="oAnimal, row in aAnimals" :key="row">
                    <td v-if="this.bModeNew"><q-checkbox v-model="oAnimal.send" :val="row" /></td>
                    <td v-for="(oComponent, i) in aEditedComponents" :key="i">
                        <component 
                            :bHideLabel="true"
                            :is="oComponent.sComponent" 
                            :sEndPoint="oComponent.sEndPoint" 
                            :sKeyField="oComponent.sName"
                            :aOptions="oComponent.aOptions"
                            :bDisabled="oComponent.bDisabled || !bModeNew"
                            :oValorField="oAnimal"
                            @updateValor="onGetValuesForm($event, row, oComponent.sName)"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>  
    </template>
</template>
<script>
import FormText from '@/components/Fields/FormText.vue'
import FormNumber from '@/components/Fields/FormNumber.vue'
import FormArea from '@/components/Fields/FormArea.vue'
import FormDate from '@/components/Fields/FormDate.vue';
import FormSelect from '@/components/Fields/FormSelect'
import FormComparison from '@/components/Fields/FormComparison'

import axiosServices from '@/services/axiosServices';


export default {
    name: 'DispatchGuideTable',
    props: {
        aSubComponents: {},
        oValorField: {
            default: null
        },
        sKeyField: {
            type: String,
            default: ''
        },
        sEndPoint: {
            type: String,
            default: ''
        },
        oFormField: {},
        bModeNew: {
            type: Boolean,
            default: false
        },
        sSubDependsOn: {
            type: String,
            default: null
        },
    },
    components: {
        FormText,
        FormArea,
        FormSelect,
        FormNumber,
        FormDate,
        FormComparison,
    },
    data() {
        return {
            bLoading: false,
            aAnimals: [],
            aLocalFields: [],
            aEditedComponents: [],
            sLastSubDepends: null,
        }
    },
    mounted() {
        if(this.oValorField && this.oValorField[`${this.sKeyField}`]) {
            let aAnimals = this.oValorField[`${this.sKeyField}`];
            this.aAnimals = aAnimals.map(oAnimal => {
                return {...oAnimal, send: false};
            });
            this.onGetData();
        }
        if(this.bModeNew) {
            this.setNewEndPoint(this.sEndPoint);
        }
    },
    methods: {
        async onGetData() {
            this.bLoading = true;
            let aEndPoints = [];
            aEndPoints = this.aSubComponents.filter(oComponent => oComponent.sEndPoint);
            aEndPoints = aEndPoints.map(oComponent => {return oComponent.sEndPoint});
            let uniquedata = new Set(aEndPoints);
            let endpoints = Array.from(uniquedata);
            let axiosData = await this.onSetEndPointsData(endpoints);
            let oEndPoints = {};
            aEndPoints.forEach((sEndPoint, key) => {
                oEndPoints[sEndPoint] = axiosData[key];
            });

            this.aEditedComponents = this.aSubComponents.map(oComponent => {
                oComponent['aOptions'] = oEndPoints[oComponent.sEndPoint] ?? [];
                return oComponent;
            });

            this.bLoading = false;
        },
        onSetEndPointsData(data) {
            return new Promise((resolve, reject) => {
                Promise.all(data.map((element) => {
                    return axiosServices.onAxiosGet(element)
                        .then(response => response)
                        .catch(error => Promise.reject(error));
                }))
                .then(axiosData => resolve(axiosData))
                .catch(error => reject(error));
            });
        },
        onGetValuesForm(data, row, sName) {
            if(sName == 'amount') {
                this.aAnimals[row].amount = data;
            }
        },
        async setNewEndPoint(sNewEndPoint) {
            let sEndPoint = this.sSubDependsOn ? `${sNewEndPoint}/${this.oFormField[this.sSubDependsOn]}` : sNewEndPoint;
            if(sNewEndPoint.split('/')[1] && sNewEndPoint.split('/')[1] !== 'undefined' && !this.oValorField[`${this.sKeyField}`]) {
                this.aAnimals = [];
                const response = await axiosServices.onAxiosGet(sEndPoint);
                this.aAnimals = response.data.map(dailyPayroll => {
                    return {...dailyPayroll, send: false};
                });
                this.onGetData();
            }
        },
    },
    watch: {
        aAnimals: {
            handler(newAnimals) {
                let animalsToSend = newAnimals.filter(oAnimal => oAnimal.send == true);
                this.$emit('updateValor', animalsToSend);
            },
            deep: true
        },
        oFormField: {
            handler(newFormField) {
                this.sLastSubDepends = newFormField[`${this.sSubDependsOn}`]
            },
            deep: true
        },
        sLastSubDepends() {
            this.setNewEndPoint(this.sEndPoint)
        },
        sEndPoint(sNewEndPoint) {
            this.setNewEndPoint(sNewEndPoint);
        },
    },
}
</script>
<style>
.form-table td{
    vertical-align: top;
}
</style>