import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

// Modulos
import systems from '@/store/systems/systems';
import features from '@/store/features/features';


const vuexSession = new VuexPersistence({
  storage: sessionStorage,
})

export default createStore({
  state: {
    bLogin: false,
    sToken: '',
    oUser: {},
    aMenu: [],
    aPermissions : [],
  },
  getters: {
    onGetLogin: (state) => {
      return state.bLogin
    },
    onGetToken: (state) => {
      return state.sToken
    },
    onGetMenu: (state) => {
      return state.aMenu
    },
    onGetPermissions: (state) => {
      return state.aPermissions
    },
    onGetUser: (state) => {
      return state.oUser
    },
    onGetPermission: (state) => (name) => {
      return state.aPermissions.find(permission => permission == name) ? true : false;
    }
  },
  mutations: {
    SETLOGIN: (state, value) => {
      state.bLogin = value
    },
    SETTOKEN: (state, value) => {
      state.sToken = value
    },
    SETMENU: (state, value) => {
      state.aMenu = value
    },
    SETPERMISSIONS: (state, value) => {
      state.aPermissions = value
    },
    SETUSER: (state, value) => {
      state.oUser = value
    },
  },
  actions: {
    onSetLogin({commit}, value) {
      commit('SETLOGIN', value)
    },
    onSetToken({commit}, value) {
      commit('SETTOKEN', value)
    },
    onSetMenu({commit}, value) {
      commit('SETMENU', value)
    },
    onSetPermissions({commit}, value) {
      commit('SETPERMISSIONS', value)
    },
    onSetUser({commit}, value) {
      commit('SETUSER', value)
    },
  },
  modules: {
    systems,
    features
  },
  plugins: [vuexSession.plugin]
})
