<template>
    <div class="container q-mx-md q-my-lg">
        <DefaultGrid :title="title" :endpoint="endpoint" :permissions="permissions" :fields="fields"
            :modal-size="modalSize" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
export default {
    name: "SeizureComparison",
    components: {
        DefaultGrid
    },
    data() {
        return {
            title: 'Comparación Decomisos',
            endpoint: 'seizureComparison',
            fields: [
                {
                    sName: 'date',
                    sLabel: 'Fecha',
                    sComponent: 'FormDate',
                    nCols: '12'
                },
                {
                    sName: 'id_outlet',
                    sLabel: 'Expendio',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltAntemoremOutlets/seizureComparisons',
                    sVisible: 'create',
                    nCols: 6
                },
                {
                    sName: 'outlet',
                    sLabel: 'Expendio',
                    sComponent: 'FormText',
                    bDisabled: true,
                    sVisible: 'edit',
                    nCols: 6,
                },
                {
                    sName: 'id_daily_payroll',
                    sLabel: '# Animal',
                    sComponent: 'FormSelect',
                    sEndPoint: 'sltAntemoremAnimals/seizureComparisons',
                    sDependsOn: 'id_outlet',
                    sVisible: 'create',
                    nCols: 6
                },
                {
                    sName: 'code',
                    sLabel: '# Animal',
                    sComponent: 'FormText',
                    bDisabled: true,
                    sVisible: 'edit',
                    nCols: 6,
                },

                {
                    sName: 'head_quantity',
                    sLabel: 'Cabeza - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'small_ints_quantity',
                    sLabel: 'Int. Delagdo - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'large_ints_quantity',
                    sLabel: 'Int. Grueso - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'oment_quantity',
                    sLabel: 'Omento - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'renet_quantity',
                    sLabel: 'Cuajo - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'callus_quantity',
                    sLabel: 'Callo - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'liver_quantity',
                    sLabel: 'Hígado - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'lungs_quantity',
                    sLabel: 'Pulmones - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: '1', name: '1' }, { id: '2', name: '2' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'legs_quantity',
                    sLabel: 'Patas - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: '1', name: '1' }, { id: '2', name: '2' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'hands_quantity',
                    sLabel: 'Manos - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: '1', name: '1' }, { id: '2', name: '2' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'udder_quantity',
                    sLabel: 'Ubre - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'kidney_quantity',
                    sLabel: 'Riñones - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: '1', name: '1' }, { id: '2', name: '2' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'heart_quantity',
                    sLabel: 'Corazón - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'booklet_quantity',
                    sLabel: 'Librillo - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }, { id: 'PARCIAL', name: 'PARCIAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'white_viscera_quantity',
                    sLabel: 'Viscera Blanca - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'red_viscera_quantity',
                    sLabel: 'Viscera Roja - Cantidad',
                    sComponent: 'FormSelect',
                    aOptions: [{ id: 'TOTAL', name: 'TOTAL' }],
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'destocking_quantity',
                    sLabel: 'Despurgos - Cantidad',
                    sComponent: 'FormNumber',
                    bClearable: true,
                    nCols: 6
                },

                {
                    sName: 'canal_quantity',
                    sLabel: 'Canal - Cantidad',
                    sComponent: 'FormNumber',
                    nCols: 6
                },

                {
                    sName: 'other_organ',
                    sLabel: 'Otros - Órgano',
                    sComponent: 'FormText',
                    nCols: 6
                },

                {
                    sName: 'other_quantity',
                    sLabel: 'Otros - Cantidad',
                    sComponent: 'FormText',
                    nCols: 6
                },
            ]
        }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px;
}
</style>