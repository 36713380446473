<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "ChannelConditioning",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Acondicionamiento de la canal',
        endpoint: 'channelConditioning',
        fields: [
          {
              sName: 'date',
              sLabel: 'Fecha',
              sComponent: 'FormDate',
              nCols: 12
          },
          {
              sName: 'id_outlet',
              sLabel: 'Expendio',
              sComponent: 'FormSelect',
              sEndPoint: 'sltAntemoremOutlets/channelConditioning',
              sVisible: 'create',
              nCols: 6
            },
            {
              sName: 'outlet',
              sLabel: 'Expendio',
              sComponent: 'FormText',
              bDisabled: true,
              sVisible: 'edit',
              nCols: 6,
            },
            {
              sName: 'id_daily_payroll',
              sLabel: '# Animal',
              sComponent: 'FormSelect',
              sEndPoint: 'sltAntemoremAnimals/channelConditioning',
              sDependsOn: 'id_outlet',
              sVisible: 'create',
              nCols: 6
            },
            {
              sName: 'code',
              sLabel: '# Animal',
              sComponent: 'FormText',
              bDisabled: true,
              sVisible: 'edit',
              nCols: 6,
            },
          {
              sName: 'skin',
              sLabel: 'Piel',
              sComponent: 'FormText',
              nCols: 6
          },
          {
              sName: 'hair',
              sLabel: 'Cabello',
              sComponent: 'FormText',
              nCols: 6
          },
          {
              sName: 'hematoma',
              sLabel: 'Hematoma',
              sComponent: 'FormText',
              nCols: 6
          },
          {
              sName: 'abscess',
              sLabel: 'Abseso',
              sComponent: 'FormText',
              nCols: 6
          },
          {
              sName: 'parasite',
              sLabel: 'Parásito',
              sComponent: 'FormText',
              nCols: 6
          },
          {
              sName: 'other',
              sLabel: 'Otro',
              sComponent: 'FormText',
              nCols: 6
          },
          {
              sName: 'correction',
              sLabel: 'Corrección',
              sComponent: 'FormText',
              nCols: 6
          },
          {
              sName: 'quantity',
              sLabel: 'Cantidad',
              sComponent: 'FormNumber',
              nCols: 6
          }
        ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>