<template>
  <div class="container q-mx-md q-my-lg">
    <DefaultGrid
      :title="title"
      :endpoint="endpoint"
      :permissions="permissions"
      :fields="fields"
      :modal-size="modalSize"
      :hideID="true"
      :hasEXCEL="true"
      :isMaximized="true"
      :isDisableDelete="true"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
export default {
  name: "DispatchGuide",
  components: {
    DefaultGrid
  },
  data() {
    return {
      title: 'Guía de despacho',
      endpoint: 'dispatchGuides',
      fields: [
        {
          sName: 'sacrifice_date',
          sLabel: 'Fecha de Sacrificio',
          sComponent: 'FormDate',
          nCols: 6
        },
        {
          sName: 'dispatch_time',
          sLabel: 'Hora de despacho',
          sComponent: 'FormTime',
          nCols: 6
        },
        {
          sName: 'id_outlet',
          sLabel: 'Expendio',
          sComponent: 'FormSelect',
          sEndPoint: 'sltDispatchGuideOutlets',
          sDependsOn: 'sacrifice_date',
          sVisible: 'create',
          nCols: 6
        },
        {
          sName: 'outlet',
          sLabel: 'Expendio',
          sComponent: 'FormText',
          bDisabled: true,
          sVisible: 'edit',
          nCols: 6,
        },
        {
          sName: 'closing_date',
          sLabel: 'Fecha de cierre',
          sComponent: 'FormDate',
          nCols: 6
        },
        {
          sName: 'closing_time',
          sLabel: 'Hora de cierre',
          sComponent: 'FormTime',
          nCols: 6
        },
        {
          sName: 'average_temperature',
          sLabel: 'Temperatura promedio',
          sComponent: 'FormNumber',
          nCols: 6
        },

        {
          sName: 'animals',
          sComponent: 'DispatchGuideTable',
          sEndPoint: 'dailyPayrollOutlets',
          sDependsOn: 'id_outlet',
          sSubDependsOn: 'sacrifice_date',
          nCols: 12,
          aSubComponents: [
              {
                  sName: 'code',
                  sLabel: 'Código',
                  sComponent: 'FormText',
                  sWidth: '100px',
                  bDisabled: true,
              },
              {
                  sName: 'product_type',
                  sLabel: 'Tipo de producto',
                  sComponent: 'FormText',
                  sWidth: 'auto',
                  bDisabled: true
              },
              {
                  sName: 'special_order',
                  sLabel: 'Orden especial',
                  sComponent: 'FormArea',
                  sWidth: 'auto',
                  bDisabled: true
              },
              {
                  sName: 'comparison',
                  sLabel: 'Comparación',
                  sComponent: 'FormComparison',
                  sWidth: '100px',
                  bDisabled: true,
              },
              {
                  sName: 'amount',
                  sLabel: 'Cantidad',
                  sComponent: 'FormNumber',
                  sWidth: 'auto',
                  bDisabled: false
              },
          ]
        },

        {
          sName: 'id_vehicle',
          sLabel: 'Vehiculo',
          sComponent: 'FormSelect',
          sEndPoint: 'sltVehicles',
          nCols: 6
        },
        {
          sName: 'approved',
          sLabel: 'Dictamen de producto',
          sComponent: 'FormSelect',
          sEndPoint: 'sltVehicles',
          aOptions: [
            { id: 1, name: 'APROBADO' },
            { id: 0, name: 'APROBADO CONDICIONAL' },
          ],
          nCols: 6
        },
        {
          sName: 'white_viscera',
          sLabel: 'Viscera blanca',
          sComponent: 'FormText',
          nCols: 6
        },
        {
          sName: 'red_viscera',
          sLabel: 'Viscera roja',
          sComponent: 'FormText',
          nCols: 6
        },
        {
          sName: 'heads',
          sLabel: 'Cabezas',
          sComponent: 'FormText',
          nCols: 6
        },
        {
          sName: 'legs',
          sLabel: 'Patas',
          sComponent: 'FormText',
          nCols: 6
        },
        {
          sName: 'others',
          sLabel: 'Otros',
          sComponent: 'FormText',
          nCols: 12
        },
        {
          sName: 'observations',
          sLabel: 'Observaciones',
          sComponent: 'FormArea',
          nCols: 12
        },
      ]
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>