<template>
  <div class="container q-mx-md q-my-lg">
    <DefaultGrid
      :title="title"
      :endpoint="endpoint"
      :isFormdata="true"
      :permissions="permissions"
      :fields="fields"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
export default {
  name: "RoutesPage",
  components: {
    DefaultGrid
  },
  data() {
    return {
      title: 'Rutas',
      endpoint: 'routes',
      fields: [
        {
            sName: 'name',
            sLabel: 'Nombre',
            sComponent: 'FormText',
            nCols: 12
        },
      ]
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>