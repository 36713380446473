<template>
  <div class="container q-mx-md q-my-lg">
    <DefaultGrid
      :title="title"
      :endpoint="endpoint"
      :isFormdata="true"
      :permissions="permissions"
      :fields="fields"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
export default {
  name: "PersonasPage",
  components: {
    DefaultGrid
  },
  data() {
    return {
      title: 'Personas',
      endpoint: 'persons',
      fields: [
        {
          sName: 'fullname',
          sLabel: 'Nombre',
          sComponent: 'FormText',
          sEndPoint: '',
          nCols: 6
        },
        {
          sName: 'document',
          sLabel: 'Documento',
          sComponent: 'FormNumber',
          sEndPoint: '',
          nCols: 6
        },
        {
          sName: 'expedition_city',
          sLabel: 'Ciudad de Expedición',
          sComponent: 'FormText',
          sEndPoint: '',
          nCols: 6
        },
        {
          sName: 'adress',
          sLabel: 'Dirección',
          sComponent: 'FormText',
          sEndPoint: '',
          nCols: 6
        },
        {
          sName: 'phone',
          sLabel: 'Teléfono',
          sComponent: 'FormText',
          sEndPoint: '',
          nCols: 12
        },
        {
          sName: 'signature',
          sLabel: 'Firma',
          sComponent: 'FormFileImage',
          sEndPoint: 'signature',
          nCols: 12
        },
        {
          sName: 'authorization',
          sLabel: 'Autorización',
          sComponent: 'FormFile',
          sEndPoint: 'authorization',
          nCols: 12
        },
      ]
    }
  },
  methods: {},
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>