<template>
    <div class="container q-mx-md q-my-lg">
      <DefaultGrid
        :title="title"
        :endpoint="endpoint"
        :permissions="permissions"
        :fields="fields"
        :modal-size="modalSize"
        :hasEXCEL="true"
      />
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import DefaultGrid from '@/components/Grid/DefaultGrid.vue';
  export default {
    name: "SuspiciousAnimalsPage",
    components: {
      DefaultGrid
    },
    data() {
      return {
        title: 'Animales Sospechosos',
        endpoint: 'suspiciousAnimals',
        fields: [
            {
                sName: 'date',
                sLabel: 'Fecha',
                sComponent: 'FormDate',
                nCols: 6
            },
            {
                sName: 'time',
                sLabel: 'Hora',
                sComponent: 'FormTime',
                nCols: 6
            },
            {
                sName: 'id_supervisor',
                sLabel: 'Supervisor',
                sComponent: 'FormSelect',
                sEndPoint: 'sltUsersIdByCharge/4',
                nCols: 6
            },
            {
                sName: 'id_responsable',
                sLabel: 'Responsable',
                sComponent: 'FormSelect',
                sEndPoint: 'sltUsersIdByCharge/8',
                nCols: 6
            },
            {
                sName: 'id_veterinary',
                sLabel: 'Veterinario',
                sComponent: 'FormSelect',
                sEndPoint: 'sltUsersIdByCharge/5',
                nCols: 6
            },
            {
                sName: 'id_owner',
                sLabel: 'Propietario',
                sComponent: 'FormSelect',
                sEndPoint: 'sltUsersIdByCharge/2',
                nCols: 6
            },
            {
                sName: 'iron',
                sLabel: 'Hierro',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'corral_location',
                sLabel: 'Ubicación Corral',
                sComponent: 'FormText',
                nCols: 6
            },
            {
                sName: 'id_department_source',
                sLabel: 'Departamento Procedencia',
                sComponent: 'FormSelect',
                sEndPoint: 'sltDepartments',
                nCols: 6
            },
            {
                sName: 'id_location',
                sLabel: 'Ciudad Procedencia',
                sComponent: 'FormSelect',
                sEndPoint: 'sltCities',
                sDependsOn: 'id_department_source',
                nCols: 6
            },

            {
                sName: 'id_guide',
                sLabel: 'Guía',
                sComponent: 'FormSelect',
                sEndPoint: 'sltGuideThroughMaster/suspicious_animals',
                sDependsOn: 'date',
                sVisible: 'create',
                nCols: 6
            },
            {
                sName: 'guide',
                sLabel: 'Guía',
                sComponent: 'FormText',
                sVisible: 'edit',
                bDisabled: true,
                nCols: 6
            },
            {
                sName: 'id_daily_payroll',
                sLabel: '# Animal',
                sComponent: 'FormSelect',
                sEndPoint: 'sltPayrrollsGuide/suspiciousAnimals',
                sDependsOn: 'id_guide',
                sVisible: 'create',
                nCols: 6
            },
            {
                sName: 'animal_code',
                sLabel: '# Animal',
                sComponent: 'FormText',
                sVisible: 'edit',
                bDisabled: true,
                nCols: 6
            },
            {
                sName: 'weight',
                sLabel: 'Peso',
                sComponent: 'FormNumber',
                nCols: 6
            },
            {
                sName: 'temperature',
                sLabel: 'Temperatura',
                sComponent: 'FormNumber',
                nCols: 6
            },
            {
                sName: 'heart_frequency',
                sLabel: 'Frecuencia Cardiaca',
                sComponent: 'FormNumber',
                nCols: 6
            },
            {
                sName: 'respiratory_frequency',
                sLabel: 'Frecuencia Respiratoria',
                sComponent: 'FormNumber',
                nCols: 6
            },
            {
                sName: 'findings',
                sLabel: 'Hallazgos',
                sComponent: 'FormArea',
                nCols: 6
            },
            {
                sName: 'observations',
                sLabel: 'Observaciones',
                sComponent: 'FormArea',
                nCols: 6
            },
        ]
      }
    },
    methods: {},
    mounted() {
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
  }
  </script>
  <style lang="scss">
  .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px; 
  }
  </style>