<template>
  <div class="container q-mx-md q-my-lg">
    <q-card bordered>
      <q-card-section>
        <div class="text-h5">Reportes</div>
      </q-card-section>
    </q-card>

    <q-list bordered class="q-mt-md rounded-borders bg-white">
      <template v-for="card, key in cards" :key="key">
        <q-expansion-item>
          <template v-slot:header>
            <q-item-section avatar>
              <q-icon :name="card.icon" />
            </q-item-section>
  
            <q-item-section v-text="card.text" />
          </template>
          <q-item @click="onShowModal(subcard)" v-for="subcard, key in card.aCards" :key="key" clickable>
            <q-item-section v-text="subcard.sHeader" />
            <q-item-section avatar>
              <q-icon name="download" />
            </q-item-section>
          </q-item>
        </q-expansion-item>
        <q-separator />
      </template>
    </q-list>
  </div>
  <ReportForm
    :key="nKey"
    :title="card.sHeader"
    :fields="card.aComponents"
    :endpoint="card.sEndPoint"
    :format-name="card.sFormatName"
    v-model="showModal"
    @onClose="onClose"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import ReportForm from '@/components/Form/ReportForm.vue';
// import axiosServices from '@/services/axiosServices';
export default {
  name: "ReportsPage",
  components: {
    ReportForm
  },
  data() {
    return {
      nKey: 0,
      showModal: false,
      card: {},
      cards: [
            {
                icon: 'fact_check',
                text: 'Recepción',
                aCards: [
                  {
                      sHeader: "Matriz Diaria",
                      sEndPoint: "dailyMatrix",
                      sFormatName: "MATRIZ DIARIA",
                      aComponents: [
                          {
                              sName: 'benefit_date',
                              sLabel: 'Fecha de beneficio',
                              sComponent: 'FormDate',
                              nCols: 12
                          },
                      ]
                  },
                  {
                      sHeader: "Planilla Diaria",
                      sEndPoint: "dailyPayroll",
                      sFormatName: "Planilla diaria",
                      aComponents: [
                        {
                            sName: 'date',
                            sLabel: 'Fecha',
                            sComponent: 'FormDate',
                            nCols: 12
                        },
                      ]
                  },
                  {
                      sHeader: "Registro Diario Antemortem",
                      sEndPoint: "antemortemDailyRecord",
                      sFormatName: "PLANILLA DIARIA ANTEMORTEM",
                      aComponents: [
                          {
                              sName: 'sacrifice_date',
                              sLabel: 'Fecha de Beneficio',
                              sComponent: 'FormDate',
                              nCols: 12
                          },
                      ]
                  },
                  {
                      sHeader: "Edades Bovinos",
                      sEndPoint: "ageBobins",
                      sFormatName: "EDADES BOVINOS",
                      aComponents: [
                          {
                              sName: 'date',
                              sLabel: 'Fecha de sacrificio',
                              sComponent: 'FormDate',
                              nCols: 12
                          },
                      ]
                  },
                ]
            },
                        {
                icon: 'history',
                text: 'Ante-Mortem',
                aCards: [
                    {
                        sHeader: "Inspección Antemortem",
                        sEndPoint: "antemortemInspection",
                        sFormatName: "Inspección Antemortem",
                        aComponents: [
                            {
                                sName: 'date',
                                sLabel: 'Fecha',
                                sComponent: 'FormDate',
                                nCols: 12
                            },
                        ]
                    },
                ]
            },
            {
                icon: 'feed',
                text: 'Post-Mortem',
                aCards: [
                    {
                        sHeader: "Ruta Diaria",
                        sEndPoint: "dailyRoutes",
                        sFormatName: "Ruta Diaria",
                        aComponents: [
                            {
                                sName: 'date',
                                sLabel: 'Fecha',
                                sComponent: 'FormDate',
                                nCols: 12
                            },
                        ]
                    },
                    {
                        sHeader: "Orden Beneficio",
                        sEndPoint: "formBenefitOrder",
                        sFormatName: "Orden de Beneficio",
                        aComponents: [
                            {
                                sName: 'date',
                                sLabel: 'Fecha',
                                sComponent: 'FormDate',
                                nCols: 12
                            },
                        ]
                    },
                    {
                        sHeader: "Inspección Post Mortem",
                        sEndPoint: "postmortemInspections",
                        sFormatName: "Inspeccion Post Mortem",
                        aComponents: [
                            {
                                sName: 'date',
                                sLabel: 'Fecha',
                                sComponent: 'FormDate',
                                nCols: 12
                            },
                        ]
                    },
                    {
                        sHeader: "Tolerancia Cero Visceras",
                        sEndPoint: "zeroGutsTolerance",
                        sFormatName: "Tolerancia Cero Visceras",
                        aComponents: [
                            {
                                sName: 'date',
                                sLabel: 'Fecha',
                                sComponent: 'FormDate',
                                nCols: 12
                            },
                        ]
                    },
                    {
                        sHeader: "Tolerancia Cero Canales",
                        sEndPoint: "zeroToleranceInspection",
                        sFormatName: "Tolerancia Cero Canales",
                        aComponents: [
                            {
                                sName: 'date',
                                sLabel: 'Fecha',
                                sComponent: 'FormDate',
                                nCols: 12
                            },
                        ]
                    },
                    {
                        sHeader: "Despacho Visceras",
                        sEndPoint: "visceraDispatch",
                        sFormatName: "Despacho Visceras",
                        aComponents: [
                            {
                                sName: 'date',
                                sLabel: 'Fecha',
                                sComponent: 'FormDate',
                                nCols: 12
                            },
                        ]
                    },
                    {
                        sHeader: "Comparación Decomisos",
                        sEndPoint: "seizureComparison",
                        sFormatName: "Comparación Decomisos",
                        aComponents: [
                        {
                                sName: 'date',
                                sLabel: 'Fecha',
                                sComponent: 'FormDate',
                                nCols: 12
                            },
                        ]
                    },
                ]
            },
      ]
    }
  },
  methods: {
    onShowModal(card) {
      this.nKey += 1;
      this.showModal = true;
      this.card = card;
    },
    onClose() {
      this.showModal = false;
      this.card = {};
    }
  },
  mounted() {
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>
<style lang="scss">
.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px; 
}
</style>